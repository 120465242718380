import { TESTNET_CONFIG, MAINNET_CONFIG } from '../constants'

export function trimName(name: string, start = 18, end = 16, total = 40): string {
  if (name?.length > total) {
    return `${name.substring(0, start)}...${name.substring(name.length - end)}`
  } else {
    return name
  }
}

export const sleep = (ms: number): Promise<any> => new Promise(resolve => setTimeout(resolve, ms))

export const getExplorerLink = (
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block' | 'contract',
): string => {
  const config = process.env.REACT_APP_NETWORK_ID === 'testnet' ? TESTNET_CONFIG : MAINNET_CONFIG
  const prefix = config.explorerUrl

  switch (type) {
    case 'transaction':
      return `${prefix}/transactions/${data}`
    case 'block':
      return `${prefix}/blocks/${data}`
    case 'token':
    case 'contract':
    case 'address':
      return `${prefix}/accounts/${data}`
    default:
      break
  }
}
