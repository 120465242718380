export type Order = 'asc' | 'desc'

export interface Data {
  poolId: number
  name: string
  listing: number
  floorPrice: number
  liquidity: number
  volume: number
}

export interface HeadCell {
  id: keyof Data
  label: string
  numeric: boolean
  tooltip: boolean
  tooltipText: string
  direction: '' | 'up' | 'down'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
