import { IconButton, MenuItem } from '@mui/material'
import { BiMenu } from 'react-icons/bi'
import { MdClose } from 'react-icons/md'
import React from 'react'
import NAV_ITEMS from './items'
import { MobileMenuA, MobileMenuLink, MobileMenuWrapper, SubText } from './Styled'
import { useLocation } from 'react-router-dom'

function MenuMobile(): JSX.Element {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const location = useLocation()
  const { pathname } = location

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        sx={{ color: '#fff' }}
      >
        {anchorElNav !== null ? <MdClose /> : <BiMenu />}
      </IconButton>
      <MobileMenuWrapper
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', lg: 'none' },
        }}
      >
        {NAV_ITEMS.map(navItem => (
          <MenuItem key={navItem.label} onClick={handleCloseNavMenu}>
            {navItem.href && (
              <MobileMenuA href={navItem.href} target={navItem.target ?? '_self'}>
                {navItem.label}
              </MobileMenuA>
            )}
            {navItem.to && (
              <MobileMenuLink to={navItem.to} className={`${pathname.includes(navItem.slug) && 'active'}`}>
                {navItem.label}
              </MobileMenuLink>
            )}
            {navItem.subLabel && <SubText>{navItem.subLabel}</SubText>}
          </MenuItem>
        ))}
      </MobileMenuWrapper>
    </>
  )
}

export default MenuMobile
