import { Box } from '@mui/material'
import styled from 'styled-components/macro'

export const StyledBox = styled.div.attrs(props => ({
  // @ts-ignore
  hover: props.hover || 'true',
}))`
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  height: 100%;
  cursor: ${props => (props.hover === 'true' ? 'pointer' : 'initial')};
  transition: all 0.5s ease;

  &:hover {
    background-color: ${props => (props.hover === 'true' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.1)')};
  }
`
export const Title = styled.h3`
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1.5rem;
`
export const SubTitle = styled.h3`
  font-size: 1.5rem;
`
export const Description = styled.p`
  font-weight: 500;
  color: rgba(252, 253, 253, 0.8);
  margin-bottom: 0;
`

export const CreatePoolForm = styled.form`
  background-color: rgba(255, 255, 255, 0.1);
  display: grid;
  row-gap: 1rem;
  padding: 1rem;
`

export const InputFieldWrapper = styled.div`
  display: block;

  select,
  input:not(#react-select-3-input) {
    height: 36px;
    padding: 2px 8px;
    border-radius: 4px;
    width: 100%;
  }
`

export const InputLabel = styled.label`
  display: block;
  color: #fff;
  margin-bottom: 0.5rem;
`

// NFT Grid Item
export const NFTItemWrapper = styled.div`
  margin-bottom: 0.625rem;
  width: 50%;
  flex: 0 0 50%;
  max-height: 400px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;

  &:nth-child(2n) {
    padding-left: 5px;
  }

  &:nth-child(2n + 1) {
    padding-right: 5px;
  }

  &.is-selected {
    > div {
      box-shadow: 0 0 5px #ef4a31cc;

      @media (min-width: 48rem) {
        box-shadow: 0 0 15px $ef4a31cc;
      }
    }

    .nft-checkbox::before {
      background-color: #ef4a31;
    }

    .nft-checkbox svg {
      display: block;
    }
  }

  @media (min-width: 48rem) {
    margin-bottom: 30px;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  @media (min-width: 62rem) {
    width: 25%;
    flex: 0 0 25%;
  }
`
export const NFTItemInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.05);
  overflow: hidden;
  height: 100%;
  transition: all 0.5s ease;

  &:hover {
    box-shadow: 0 0 5px ${props => props.theme.primary}cc;

    @media (min-width: 48rem) {
      box-shadow: 0 0 15px ${props => props.theme.primary}cc;
    }
  }
`
export const NFTImage = styled.div`
  position: relative;
  max-height: 120px;

  @media (min-width: 26.5625rem) {
    max-height: 200px;
  }

  @media (min-width: 36rem) {
    max-height: 300px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
`
export const NFTCheckbox = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 100%;
  height: 1.75rem;
  width: 1.75rem;
  color: #fff;
  background-color: #fff;
  box-shadow: 0 1px 9px #373f5c36;

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    display: block;
    border-radius: 100%;
    height: 1.25rem;
    width: 1.25rem;
    background-color: #e6e8ec;
  }

  svg {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
export const NFTDescription = styled(Box)`
  padding: 1rem;
`
export const NFTDescriptionLoading = styled(NFTDescription)`
  height: 25%;
`
export const NFTName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0.33rem;
  color: #fff;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const NFTId = styled.div`
  font-size: 0.875rem;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`
export const SelectAll = styled.div`
  position: relative;
  cursor: pointer;
  box-shadow: 0 1px 9px #373f5c36;
  color: #fff;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 100%;
    height: 1.75rem;
    width: 1.75rem;
    background-color: #fff;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    border-radius: 100%;
    height: 1.25rem;
    width: 1.25rem;
    background-color: #e6e8ec;
  }

  svg {
    display: none;
    position: absolute;
    top: 0.375rem;
    left: 0.35rem;
    z-index: 1;
  }

  p {
    padding-left: 2.25rem;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }

  &.is-selected {
    &::after {
      background-color: #ef4a31;
    }

    svg {
      display: block;
    }
  }
`
