import { Typography, TableRow as MuiTableRow, TableCell, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import CollectionLogo from 'components/CollectionLogo'
import { Data } from './sorting'
import Price from 'components/Price'

const StyledTableRow = styled(MuiTableRow)`
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);

  @media (max-width: 991px) {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 0.5rem 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 50%;
  }

  &::after {
    display: block;
    position: absolute;
    left: 1rem;
    bottom: 0;
    content: '';
    width: calc(100% - 3rem);
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);

    @media (max-width: 991px) {
      width: calc(100% - 2rem);
    }
  }
`
const CollectionName = styled.div`
  display: flex;
  align-items: center;

  img {
    margin: 0;
  }

  p {
    margin-left: 1rem;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    color: currentColor;
    max-width: 10rem;

    @media (min-width: 425px) and (max-width: 991px) {
      max-width: none;
    }

    @media (max-width: 424px) {
      max-width: 12rem;
    }
  }
`
const StyledTableCell = styled(TableCell)`
  color: #fff;
  padding: 1rem 1.5rem;
  border: none;

  &:first-child {
    width: 100%;

    @media (min-width: 62rem) {
      width: 20%;
    }
  }

  @media (max-width: 991px) {
    min-width: 50%;
  }

  @media (max-width: 1023px) {
    padding: 0.5rem 1rem;
  }
`
const TableLabelModel = styled.p`
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  background: linear-gradient(90deg, #f37b4a 0%, #ed3024 99.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (min-width: 62rem) {
    display: none;
  }
`

function TableRow({ row }: { row: Data }): JSX.Element {
  return (
    <StyledTableRow key={row.poolId}>
      <StyledTableCell>
        <Link
          sx={{ textDecoration: 'none', color: '#fff', '&:hover': { color: '#ef4a31' } }}
          component={RouterLink}
          to={`/collections/${row.poolId}`}
        >
          <CollectionName>
            <CollectionLogo poolName={row.name} size="2rem" borderRadius="0.5rem" />
            <Typography>{row.name}</Typography>
          </CollectionName>
        </Link>
      </StyledTableCell>
      <StyledTableCell>
        <TableLabelModel>Listing</TableLabelModel>
        <Typography sx={{ mb: 0 }}>{row.listing}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <TableLabelModel>Floor Price</TableLabelModel>
        <Price price={row.floorPrice} fontSize="1rem" noFormat={true} />
      </StyledTableCell>
      <StyledTableCell>
        <TableLabelModel>Liquidity</TableLabelModel>
        <Price price={row.liquidity} fontSize="1rem" noFormat={true} />
      </StyledTableCell>
      <StyledTableCell>
        <TableLabelModel>Volume</TableLabelModel>
        <Price price={row.volume} fontSize="1rem" noFormat={true} />
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default TableRow
