import { Table, TableBody, TableContainer, Typography } from '@mui/material'
import Layout from 'components/Layout'
import { IPool } from 'interfaces'
import { useEffect, useState } from 'react'
import NearFTSDK from 'nearft-sdk'
import { CONTRACT_ID } from '../../constants'
import EnhancedTableHead from 'components/Table/TableHead'
import { Order, Data, getComparator } from 'components/Table/sorting'
import { toRoundedReadableNumber } from 'utils/number'
import TableRow from 'components/Table/TableRow'

function Collections(): JSX.Element {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof Data>('name')
  const [isLoading, setLoading] = useState(false)
  const [rows, setRows] = useState<Data[]>([])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      setLoading(true)
      const _pools: IPool[] = await NearFTSDK.getPools(process.env.REACT_APP_NETWORK_ID ?? 'testnet', CONTRACT_ID)
      const _rows: Data[] = []

      for (let i = 0; i < _pools.length; i++) {
        const pool = _pools[i]
        _rows.push({
          poolId: pool.pool_id,
          name: pool.nft_token,
          listing: pool.pool_token_ids.length,
          floorPrice: Number(
            toRoundedReadableNumber({
              decimals: 24,
              number: pool.spot_price,
              precision: 3,
            }),
          ),
          liquidity: Number(
            toRoundedReadableNumber({
              decimals: 24,
              number: pool.near_balance,
              precision: 3,
            }),
          ),
          volume: Number(
            toRoundedReadableNumber({
              decimals: 24,
              number: pool.accumulated_volume,
              precision: 3,
            }),
          ),
        })
      }
      setRows(_rows)
      setLoading(false)
    })()
  }, [])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <Layout>
      <Typography
        variant="h1"
        sx={{
          mb: '0.5rem',
        }}
      >
        Collections
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ color: '#fcfcfd', fontWeight: 500, fontSize: '16px', lineHeight: '24px', opacity: 0.8 }}
      >
        Don't see a collection you want? Directly list your NFTs, or create a new pool to buy and sell in bulk.
      </Typography>

      <TableContainer sx={{ marginTop: '2.5rem' }} aria-labelledby="tableTitle">
        <Table>
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} isLoading={isLoading} />
          <TableBody
            sx={{
              '@media (min-width: 768px) and (max-width: 991px)': {
                display: 'flex',
                gap: '1rem',
              },
            }}
          >
            {rows.sort(getComparator(order, orderBy)).map(row => (
              <TableRow key={row.poolId} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  )
}

export default Collections
