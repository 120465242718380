import React, { useState } from 'react'
import { EControlStatus, ESize, EState, EType, MbButton, MbInput, MbText } from 'mintbase-ui'
import { useFormContext } from 'react-hook-form'
import { EInputType, VALID_STORE_NAME } from '../../../constants'
import { debounce } from 'lodash'
import { checkStoreName } from '@mintbase-js/data'
import { deployContract, execute, mbjs } from '@mintbase-js/sdk'
import { useWallet } from '@mintbase-js/react'
import styled from 'styled-components/macro'
import { LoadingButton } from '@mui/lab'

const Label = styled.label`
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 16px;
`

export const CreateStore = () => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext()

  const hasErrors = Object.keys(errors).length > 0
  const hasValues = watch().name && watch().symbol

  const checkNameExists = async (v) => {
    const res = await checkStoreName(v)
    return res?.data?.nft_contracts?.length === 0
  }

  const { selector, activeAccountId } = useWallet()

  const onSubmit = async () => {
    setLoading(true)
    const name = getValues(EInputType.NAME)
    const symbol = getValues(EInputType.SYMBOL)

    console.log('name', name)
    console.log('symbol', symbol)

    const wallet = await selector.wallet()

    const isTestnet = mbjs.keys.network === 'testnet'

    const endContractName = isTestnet ? 'mintspace2.testnet' : 'mintbase1.near'

    const callbackArgs = {
      contractName: `${name}.${endContractName}`,
    }
    const callback = {
      type: 'deploy-store',
      args: callbackArgs,
    }

    console.log('callback', callback)

    const receipt = await execute(
      { wallet, callbackArgs: callback },
      {
        ...deployContract({
          name: name,
          ownerId: activeAccountId,
          factoryContractId: endContractName,
          metadata: {
            symbol: symbol,
            baseUri: 'https://arweave.net',
          },
        }),
      },
    )
    console.log('receipt', receipt)
  }

  return (
    <>
      <div>
        <Label htmlFor={EInputType.NAME}>Store Name</Label>
        <MbInput
          {...register(EInputType.NAME, {
            required: true,
            pattern: {
              value: VALID_STORE_NAME,
              message: 'Name can only contain lowercase letters and numbers.',
            },
            maxLength: {
              value: 20,
              message: `Name can't be longer than 20 characters.`,
            },
            validate: {
              checkUrl: async (v) => {
                const isValid = await checkNameExists(v)
                return isValid || 'Name already exists.'
              },
            },
          })}
          type="text"
          placeholder="myfirststore"
          required
          controlStatus={errors[EInputType.NAME] ? EControlStatus.INVALID : EControlStatus.NORMAL}
          inputSize={ESize.MEDIUM}
          onChange={debounce(async (evt) => {
            setValue(EInputType.NAME, evt.target.value)
            await trigger(EInputType.NAME)
          }, 500)}
        />
        {errors[EInputType.NAME] && <MbText className="mt-8 cap-big-90">{errors[EInputType.NAME].message}</MbText>}
      </div>

      <div className="mt-24">
        <Label htmlFor={EInputType.NAME}>Symbol</Label>
        <MbInput
          {...register(EInputType.SYMBOL, {
            required: true,
            maxLength: {
              value: 5,
              message: `Symbol can't be longer than 5 characters.`,
            },
          })}
          type="text"
          placeholder="MFS"
          required
          style={{ background: 'transparent' }}
          controlStatus={errors[EInputType.SYMBOL] ? EControlStatus.INVALID : EControlStatus.NORMAL}
          inputSize={ESize.MEDIUM}
          hasIcon
          onChange={debounce(async (evt) => {
            setValue(EInputType.SYMBOL, evt.target.value)
            await trigger(EInputType.SYMBOL)
          }, 500)}
        />
        {errors[EInputType.SYMBOL] && <MbText className="mt-8 cap-big-90">{errors[EInputType.SYMBOL].message}</MbText>}
      </div>
      <div className="mt-24 flex justify-between">
        <LoadingButton
          type="submit"
          variant="contained"
          loading={loading}
          onClick={onSubmit}
          disabled={!activeAccountId}
          sx={{ mt: 2, mb: 4 }}
        >
          Deploy Store
        </LoadingButton>
        {/*<MbButton*/}
        {/*  label="Deploy Store"*/}
        {/*  // state={hasErrors || !hasValues ? EState.DISABLED : EState.ACTIVE}*/}
        {/*  // disabled={hasErrors ? true : !hasValues}*/}
        {/*  onClick={onSubmit}*/}
        {/*/>*/}
      </div>
    </>
  )
}
