import React, { useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { ownedStores } from '@mintbase-js/data'
import { EMintType } from 'types/mint'
import { Link, MenuItem, Select, Stack, TextField } from '@mui/material'

import { IMAGE_TYPES } from 'components/InputMedia/FileFormats'
import { tags } from '../../../constants'
import { InputMedia } from 'components/InputMedia/InputMedia'
import { InputTags } from 'components/InputTags/InputTags'
import { ToggleChip } from 'components/ToggleChip'
import styled from 'styled-components/macro'
import { useAccount } from 'state/wallet/hooks'
import { Link as RouterLink } from 'react-router-dom'

const Label = styled.label`
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 16px;
`

const MintForm = ({ control }): JSX.Element => {
  const [stores, setStores] = useState<any>([])
  const { setValue, getValues, setError, clearErrors, register, watch } = useFormContext()
  const account = useAccount()

  const uploadFile = (file: File) => {
    setValue(EMintType.MAIN_IMAGE, file)
    clearErrors(EMintType.MAIN_IMAGE)
  }

  const removeFile = () => {
    setValue(EMintType.MAIN_IMAGE, null)
    setError(
      EMintType.MAIN_IMAGE,
      {
        type: 'required',
        message: 'No image',
      },
      { shouldFocus: true },
    )
  }

  const handleSelectCategory = (value: string) => {
    const currentValue = getValues(EMintType.CATEGORY)
    if (value === currentValue) {
      setValue(EMintType.CATEGORY, null)
      return
    }
    setValue(EMintType.CATEGORY, value)
  }

  useEffect(() => {
    setError(
      EMintType.MAIN_IMAGE,
      {
        type: 'required',
        message: 'No image',
      },
      { shouldFocus: true },
    )
  }, [setError])

  useEffect(() => {
    const getData = async () => {
      const { data } = await ownedStores(account)
      if (data.nft_contracts) {
        setStores(data.nft_contracts)
      }
    }
    getData()
  }, [account])

  return (
    <>
      {stores && account && (
        <>
          <Stack direction="row" gap={1.5}>
            <Label htmlFor={EMintType.STORE}>Store</Label>
            <Link component={RouterLink} to="/create-store" sx={{ lineHeight: 1, maxHeight: '24px', mt: '19px', mb: '8px' }}>
              <span>New Store</span>
            </Link>
          </Stack>
          <Controller
            name={EMintType.STORE}
            control={control}
            render={({ field }) => (
              <Select {...field} fullWidth>
                {stores?.map((item, index) => (
                  <MenuItem value={item?.id} key={index} sx={{ color: '#000000' }}>
                    {item?.id}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </>
      )}

      <Label htmlFor={EMintType.TITLE}>Name</Label>
      <Controller name={EMintType.TITLE} control={control} render={({ field }) => <TextField {...field} fullWidth />} />

      <Label htmlFor={EMintType.DESCRIPTION}>Description</Label>
      <Controller
        name={EMintType.DESCRIPTION}
        control={control}
        render={({ field }) => <TextField {...field} multiline rows={4} fullWidth />}
      />

      <InputMedia
        {...register(EMintType.MAIN_IMAGE, {
          required: true,
          validate: () => true,
        })}
        acceptedFormats={IMAGE_TYPES}
        handleFileAdd={uploadFile}
        handleFileRemove={removeFile}
        idealDimensions="1:1"
        maxFileSize={5}
        uploadedFile={watch(EMintType.MAIN_IMAGE)}
      />

      <Label htmlFor={EMintType.MINT_AMOUNT}>Amount of items to mint</Label>
      <Controller
        name={EMintType.MINT_AMOUNT}
        control={control}
        render={({ field }) => <TextField {...field} type="number" fullWidth />}
      />

      <div className="flex pt-4 gap-4 overflow-scroll w-full no-scrollbar">
        <Label htmlFor={EMintType.CATEGORY}>Category</Label>
        <Stack direction="row" alignItems="center" flexWrap="nowrap">
          {Object.keys(tags).map((tag) => (
            <ToggleChip
              key={`${tag}`}
              isChecked={watch(EMintType.CATEGORY) === tag}
              handleClick={() => handleSelectCategory(tag)}
              disabled={false}
              label={tags[tag as keyof typeof tags]}
              {...register('categories')}
            />
          ))}
        </Stack>
      </div>

      <Label htmlFor={EMintType.TAGS}>Tags</Label>
      <InputTags
        maxTags={4}
        placeholder="Add up to 4 tags to improve discoverability"
        onTagsChange={(tagGroup) => {
          setValue(EMintType.TAGS, tagGroup)
        }}
        onMaxTags={() => alert('max')}
      />
    </>
  )
}

export default MintForm
