/* eslint-disable @typescript-eslint/no-extra-semi */
import { useDebounce, useIsWindowVisible } from 'hooks'
import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { providers } from 'near-api-js'
import { useChainId, useWalletSelector } from 'state/wallet/hooks'
import { updateBlockNumber } from './actions'

export default function Updater(): null {
  const dispatch = useDispatch()
  const chainId = useChainId()

  const windowVisible = useIsWindowVisible()
  const walletSelector = useWalletSelector()

  const [state, setState] = useState<{ chainId: number | undefined; blockNumber: number | null }>({
    chainId,
    blockNumber: null,
  })

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      setState(_state => {
        if (chainId === _state.chainId) {
          if (typeof _state.blockNumber !== 'number') return { chainId, blockNumber }
          return { chainId, blockNumber: Math.max(blockNumber, _state.blockNumber) }
        }
        return _state
      })
    },
    [chainId, setState],
  )

  // attach/detach listeners
  useEffect(() => {
    ;(async () => {
      if (walletSelector) {
        const { network } = walletSelector.selector.options
        const provider = new providers.JsonRpcProvider({ url: network.nodeUrl })

        provider
          .block({
            finality: 'final',
          })
          .then(block => {
            blockNumberCallback(block.header.height)
          })
          .catch(error => console.error(`Failed to get block number for chainId: ${chainId}`, error))
      }
    })()
  }, [walletSelector, dispatch, chainId, blockNumberCallback, windowVisible])

  const debouncedState = useDebounce(state, 100)

  useEffect(() => {
    if (!debouncedState.chainId || !debouncedState.blockNumber || !windowVisible) return
    dispatch(updateBlockNumber({ chainId: debouncedState.chainId, blockNumber: debouncedState.blockNumber }))
  }, [windowVisible, dispatch, debouncedState.blockNumber, debouncedState.chainId])

  return null
}
