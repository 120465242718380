import { Box, Grid, Typography } from '@mui/material'
import NumberInput from 'components/NumberInput'
import { usePoolParams } from 'state/pool/hooks'
import NearTokenPNG from 'assets/images/near-token.png'
import styled from 'styled-components/macro'
import BondingCurveSelect from 'components/BondingCurveSelect'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { PoolCurve, PoolType, updatePoolParams } from 'state/pool/actions'
import { useEffect, useState } from 'react'

const NearLogo = styled.img`
  display: inline-block !important;
  vertical-align: middle;
  width: 1.25rem;
  height: 1.25rem;
`

function PriceForm(): JSX.Element {
  const poolParams = usePoolParams()
  const dispatch = useDispatch<AppDispatch>()

  const [actionText, setActionText] = useState('trades')
  const [buySellText, setBuySellText] = useState('')
  const [priceAdjustText, setPriceAdjustText] = useState('')

  useEffect(() => {
    switch (poolParams.poolType) {
      case PoolType.BUY:
        setActionText('buys')
        setBuySellText('buy')
        setPriceAdjustText('down')
        break
      case PoolType.SELL:
        setActionText('sells')
        setBuySellText('sell')
        setPriceAdjustText('up')
        break
      case PoolType.BOTH:
      default:
        setActionText('trades')
        setBuySellText('')
        setPriceAdjustText('')
        break
    }
  }, [poolParams])

  const onStartPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updatePoolParams({
        poolParams: {
          ...poolParams,
          startPrice: Number(event.target.value),
        },
      }),
    )
  }

  const onDeltaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updatePoolParams({
        poolParams: {
          ...poolParams,
          delta: Number(event.target.value),
        },
      }),
    )
  }

  const onFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updatePoolParams({
        poolParams: {
          ...poolParams,
          fee: Number(event.target.value),
        },
      }),
    )
  }

  return (
    <>
      {poolParams.poolType === PoolType.BOTH && (
        <Box sx={{ mb: '1.5rem' }}>
          <NumberInput
            label="Fee Amount"
            description="The % of each trade you take as a fee"
            invalidMessage={'Must be greater than 0'}
            validWhen={poolParams.fee >= 0}
            adorment={<Typography sx={{ mb: 0, ml: '0.5rem', fontWeight: 600, textAlign: 'right' }}>%</Typography>}
            onChange={onFeeChange}
          />
        </Box>
      )}
      <Box sx={{ mb: '1.5rem' }}>
        <NumberInput
          label="Start Price"
          description="The starting price of your pool"
          showFloorPrice={true}
          invalidMessage="Must be greater than 0"
          validWhen={poolParams.startPrice >= 0}
          adorment={
            <Grid container alignItems="center" justifyContent="flex-end">
              <NearLogo src={NearTokenPNG} alt="near" />
              <Typography sx={{ mb: 0, ml: '0.5rem', fontWeight: 600 }}>NEAR</Typography>
            </Grid>
          }
          onChange={onStartPriceChange}
        />
      </Box>
      <Box sx={{ mb: '1rem' }}>
        <BondingCurveSelect />
      </Box>
      <Box>
        {poolParams.poolType === PoolType.BUY && (
          <NumberInput
            label="Delta"
            description="How much your pool's price changes with earch sell"
            showFloorPrice={true}
            invalidMessage={
              poolParams.curve === PoolCurve.EXPONENTIAL
                ? 'Delta must be greater than 0%. For flat pricing, select Linear Curve.'
                : 'Must be greater than 0'
            }
            validWhen={poolParams.curve === PoolCurve.EXPONENTIAL ? poolParams.delta > 0 : poolParams.delta >= 0}
            adorment={
              <>
                {poolParams.curve === PoolCurve.EXPONENTIAL ? (
                  <Typography sx={{ mb: 0, ml: '0.5rem', fontWeight: 600, textAlign: 'right' }}>%</Typography>
                ) : (
                  <Grid container alignItems="center" justifyContent="flex-end">
                    <NearLogo src={NearTokenPNG} alt="near" />
                    <Typography sx={{ mb: 0, ml: '0.5rem', fontWeight: 600 }}>NEAR</Typography>
                  </Grid>
                )}
              </>
            }
            onChange={onDeltaChange}
          />
        )}
        {(poolParams.poolType === PoolType.SELL || poolParams.poolType === PoolType.BOTH) && (
          <NumberInput
            label="Delta"
            description="How much your pool's price changes with earch sell"
            showFloorPrice={true}
            invalidMessage={
              poolParams.curve === PoolCurve.LINEAR
                ? `Price (${poolParams.startPrice}) must be bigger than delta (${poolParams.delta})`
                : 'Must be greater than 0'
            }
            validWhen={
              poolParams.curve === PoolCurve.LINEAR ? poolParams.delta < poolParams.startPrice : poolParams.delta >= 0
            }
            adorment={
              <>
                {poolParams.curve === PoolCurve.EXPONENTIAL ? (
                  <Typography sx={{ mb: 0, ml: '0.5rem', fontWeight: 600, textAlign: 'right' }}>%</Typography>
                ) : (
                  <Grid container alignItems="center" justifyContent="flex-end">
                    <NearLogo src={NearTokenPNG} alt="near" />
                    <Typography sx={{ mb: 0, ml: '0.5rem', fontWeight: 600 }}>NEAR</Typography>
                  </Grid>
                )}
              </>
            }
            onChange={onDeltaChange}
          />
        )}
      </Box>
      <Box sx={{ mt: '1rem' }}>
        <Typography sx={{ fontSize: '0.875rem' }}>
          You have selected a starting price of {poolParams.startPrice} NEAR.
        </Typography>
        <Typography sx={{ fontSize: '0.875rem' }}>
          Each time your pool <strong>{actionText}</strong> an NFT, your {buySellText} price will adjust{' '}
          <strong>{priceAdjustText}</strong> by {poolParams.delta}
          {poolParams.curve === PoolCurve.EXPONENTIAL ? '%' : ' NEAR'}.
        </Typography>
      </Box>
    </>
  )
}

export default PriceForm
