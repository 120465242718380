import { useSearchParams, useNavigate } from 'react-router-dom'
import { useNearBlock } from 'hooks'
import { Button } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const SuccessPage = () => {
  const [searchParams] = useSearchParams()

  const transactionHashes = searchParams.get('transactionHashes')
  const router = useNavigate()
  const { getExplorerTransactionLink } = useNearBlock()

  return (
    <div className="w-full justify-center text-center h-screen align-center flex flex-wrap items-center">
      <div className="h-auto">
        <h1 className="w-full py-10"> Your Transaction was a Success</h1>
        <div className="flex items-center justify-center gap-12">
          <Button
            variant="contained"
            endIcon={<OpenInNewIcon fontSize="small" />}
            onClick={() => window.open(getExplorerTransactionLink(transactionHashes), '_blank')}
          >
            See Transaction
          </Button>
          <Button variant="text" onClick={() => router('/')}>
            Go To Home
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SuccessPage
