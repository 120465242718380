import { Grid, Typography } from '@mui/material'
import { BiTrendingUp } from 'react-icons/bi'
import { BsArrowUpRight } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { PoolCurve, updatePoolParams } from 'state/pool/actions'
import { usePoolParams } from 'state/pool/hooks'

function BondingCurveItem({
  curve,
  isSelected,
  onSelect,
}: {
  curve: PoolCurve
  isSelected: boolean
  onSelect: () => void
}): JSX.Element {
  const currentPoolParams = usePoolParams()
  const dispatch = useDispatch<AppDispatch>()

  const onClick = () => {
    dispatch(
      updatePoolParams({
        poolParams: {
          ...currentPoolParams,
          curve,
        },
      }),
    )
    onSelect()
  }

  return (
    <Grid
      container
      padding="0.5rem 1rem"
      alignItems="center"
      sx={{
        cursor: 'pointer',
        backgroundColor: isSelected ? '#ed3024' : 'transparent',
        color: isSelected ? '#fff !important' : '#000',
        '.MuiTypography-root': {
          color: isSelected ? '#fff !important' : '#000',
        },
        '&:hover': {
          color: '#ed3024',
          '.MuiTypography-root': {
            color: '#ed3024',
          },
        },
      }}
      onClick={onClick}
    >
      {curve === PoolCurve.LINEAR ? <BsArrowUpRight size={24} /> : <BiTrendingUp size={24} />}
      <Typography sx={{ mb: 0, ml: '0.5rem' }}>
        {curve === PoolCurve.LINEAR ? 'Linear Curve' : 'Exponential Curve'}
      </Typography>
    </Grid>
  )
}

export default BondingCurveItem
