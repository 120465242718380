import { Button, Grid, Link, Stack, Tooltip, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Layout from 'components/Layout'
import Loader from 'components/Loader'
import React, { useEffect, useState } from 'react'
import NearFTSDK from 'nearft-sdk'
import { useNFTMetadata } from 'hooks'
import DefaultNFTImage from 'assets/images/collection.png'
import { InfoTitle, NFTImage } from './Styled'
import { BiLinkExternal } from 'react-icons/bi'
import { useParams, Link as RouterLink } from 'react-router-dom'
import CollectionLogo from 'components/CollectionLogo'
import { getExplorerLink } from 'utils/utils'

function NFTDetails(): JSX.Element {
  const { nftContract, tokenId } = useParams()

  const [nftInfo, setNFTInfo] = useState<any>()
  const [tokenMetadata, setTokenMetadata] = useState<any>()
  const metaDataCallback = useNFTMetadata(tokenMetadata?.reference)

  const [nftName, setNFTName] = useState('')
  const [nftImage, setNFTImage] = useState(DefaultNFTImage)
  const [nftDescription, setNFTDescription] = useState('')

  const [empty, setEmpty] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const getListNFTs = async () => {
      if (nftContract && tokenId) {
        const _tokenInfo = await NearFTSDK.getMetadataOfNFT(
          process.env.REACT_APP_NETWORK_ID ?? 'testnet',
          nftContract,
          tokenId,
        )
        if (!_tokenInfo) {
          setEmpty(true)
        } else {
          setNFTInfo(_tokenInfo)
          setTokenMetadata(_tokenInfo.metadata)
          setLoading(false)
        }
      }
    }
    getListNFTs()
  }, [nftContract, tokenId])

  useEffect(() => {
    const getNFTDetails = async () => {
      if (tokenMetadata) {
        if (tokenMetadata.reference && nftInfo) {
          const _metaData = await metaDataCallback()
          setNFTName(_metaData.title ?? _metaData.title ?? `${nftInfo?.contractId} #${tokenId}`)
          setNFTImage(_metaData.media ?? _metaData.media ?? DefaultNFTImage)
          setNFTDescription(_metaData.description && _metaData.description)
        } else {
          setNFTName(tokenMetadata.title ?? `${tokenMetadata.contractId} #${tokenId}`)
          setNFTImage(tokenMetadata.media ?? DefaultNFTImage)
        }
      }
    }
    getNFTDetails()
  }, [tokenMetadata, nftInfo])

  if (isLoading) return <Loader />

  if (empty) {
    return (
      <div className="my-20">
        <h2>Not found</h2>
      </div>
    )
  }

  return (
    <Layout>
      <Container>
        <Grid container spacing={3}>
          <Grid item sm={5}>
            <NFTImage src={nftImage} alt={nftName} />
            <Stack
              sx={{
                marginTop: '2rem',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                padding: '1rem',
              }}
            >
              <InfoTitle>Details</InfoTitle>
              <Grid
                container
                direction={{ xs: 'column', lg: 'row' }}
                justifyContent="space-between"
                sx={{ mb: '0.25rem' }}
              >
                <Typography variant="subtitle1" sx={{ color: '#fcfcfd', opacity: 0.5 }}>
                  Contract Address:
                </Typography>
                <Tooltip title="View on explorer" arrow placement="top">
                  <Link
                    href={`${getExplorerLink(nftInfo.contractId, 'contract')}`}
                    target="_blank"
                    variant="subtitle1"
                    sx={{ wordBreak: 'break-word' }}
                  >
                    {nftInfo.contractId}&nbsp;
                    <BiLinkExternal size={14} />
                  </Link>
                </Tooltip>
              </Grid>
              <Grid container direction={{ xs: 'column', lg: 'row' }} justifyContent="space-between" sx={{ mb: 0 }}>
                <Typography variant="subtitle1" sx={{ mb: '0', color: '#fcfcfd', opacity: 0.5 }}>
                  Token Id:
                </Typography>
                <Typography variant="subtitle1">{nftInfo.tokenId}</Typography>
              </Grid>
            </Stack>
          </Grid>
          <Grid item sm={7}>
            <Stack>
              <Link
                variant="body2"
                component={RouterLink}
                to={`/inventory/collection/${nftContract}`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'fit-content',
                  color: '#fff',
                  wordBreak: 'break-word',
                }}
              >
                <CollectionLogo poolName={nftContract} size="1.25rem" borderRadius="100%" />
                &nbsp;
                {nftInfo.contractId}
              </Link>
              <Typography variant="h2" sx={{ fontSize: '2rem', lineHeight: 2, marginTop: '0.5rem' }}>
                {nftName}
              </Typography>
              <Stack
                sx={{
                  marginTop: '2rem',
                  background: 'rgba(255, 255, 255, 0.05)',
                  padding: '1rem',
                }}
              >
                <Grid container sx={{ alignItems: 'center' }}>
                  <div>
                    <Typography variant="subtitle1" sx={{ color: '#fcfcfd', opacity: 0.5 }}>
                      Price
                    </Typography>
                  </div>
                  <Button
                    variant="contained"
                    sx={{
                      ml: 'auto',
                      mr: 0,
                      mt: '1rem',
                      width: '100%',
                      '@media (min-width: 992px)': {
                        width: '50%',
                        mt: 0,
                      },
                    }}
                  >
                    Sell NFT
                  </Button>
                </Grid>
                <hr />
                <div>
                  <Typography variant="subtitle1" sx={{ color: '#fcfcfd', opacity: 0.5 }}>
                    Owner
                  </Typography>
                  <Tooltip title="View on explorer" arrow placement="top">
                    <Link href={`https://explorer.testnet.near.org/accounts/${nftInfo.ownerId}`} target="_blank">
                      {nftInfo.ownerId}&nbsp;
                      <BiLinkExternal size={14} />
                    </Link>
                  </Tooltip>
                </div>
              </Stack>
              <Stack
                sx={{
                  marginTop: '2rem',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  padding: '1rem',
                }}
              >
                <InfoTitle>Properties</InfoTitle>
                <Typography variant="body1" sx={{ color: '#fcfcfd', opacity: 0.5 }}>
                  {nftDescription}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default NFTDetails
