// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react'

// Components level 1
import { Box, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { FormProvider, useForm } from 'react-hook-form'
import { EMintType } from 'types/mint'
import { useAccount } from 'state/wallet/hooks'
import { useWallet } from '@mintbase-js/react'
import { mint, execute } from '@mintbase-js/sdk'
import { uploadReference } from '@mintbase-js/storage'

// Components level 2
import Layout from 'components/Layout'
import MintForm from './components/MintForm'

const MintNFT = (): JSX.Element => {
  const [minting, setMinting] = useState<boolean>(false)

  const account = useAccount()
  const { selector, activeAccountId } = useWallet()

  console.log('activeAccountId', activeAccountId)

  const methods = useForm({
    defaultValues: {
      [EMintType.STORE]: '',
      [EMintType.TITLE]: '',
      [EMintType.DESCRIPTION]: '',
      [EMintType.MINT_AMOUNT]: 1,
      [EMintType.MAIN_IMAGE]: null,
      [EMintType.CATEGORY]: null,
      [EMintType.TAGS]: null,
      [EMintType.LOCATION]: null,
      [EMintType.FOREVER_MEDIA]: null,
      [EMintType.FOREVER_DOCUMENT]: null,
      [EMintType.WEBSITE]: null,
      [EMintType.CALENDAR]: null,
      [EMintType.FOREVER_ROYALTIES]: null,
      [EMintType.SPLIT_REVENUE]: null,
      [EMintType.CUSTOM_KEY_VALUE]: null,
    },
    mode: 'onSubmit',
  })

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods

  const onSubmit = async (data: { [x: string]: any }) => {
    const wallet = await selector.wallet()
    const file = getValues(EMintType.MAIN_IMAGE)

    if (file == null || activeAccountId == null) {
      console.error('Error uploading file')
      return
    }

    const reference = await handleUpload(file, data)
    await handleMint(reference, activeAccountId, wallet)
  }

  return (
    <Layout>
      <Typography
        variant="h1"
        sx={{
          mb: '0.5rem',
        }}
      >
        Mint your NFTs
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ color: '#fcfcfd', fontWeight: 500, fontSize: '16px', lineHeight: '24px', opacity: 0.8, mb: '2rem' }}
      >
        Let‘s mint.
      </Typography>
      <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: { xs: '1rem', md: '1.5rem' } }}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <MintForm control={control} />
            <LoadingButton
              type="submit"
              variant="contained"
              loading={minting}
              disabled={!account}
              sx={{ mt: 2, mb: 4 }}
            >
              Mint
            </LoadingButton>
          </form>
        </FormProvider>
      </Box>
    </Layout>
  )
}

export default MintNFT

async function handleUpload(file, data) {
  const metadata = {
    title: data[EMintType.TITLE],
    description: data[EMintType.DESCRIPTION],
    media: file
  };

  const referenceJson = await uploadReference(metadata)
  console.log("Successfully uploaded with referece:", referenceJson.id)
  return referenceJson.id
}

async function handleMint(reference, activeAccountId, wallet) {

  if (reference) {
    const mintCall = mint({
      reference: reference,
      ownerId: activeAccountId
    })

    await execute({ wallet }, mintCall)
  }
}
