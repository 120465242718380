import { useEffect, useState } from 'react'
import NearFTSDK from 'nearft-sdk'
import { useParams } from 'react-router-dom'
import { Container, Grid, Link, Tooltip } from '@mui/material'
import { CONTRACT_ID } from '../../constants'
import Layout from 'components/Layout'
import Loader from 'components/Loader'
import Price from 'components/Price'
import NFTBox from 'components/NFTBox'
import CollectionLogo from 'components/CollectionLogo'
import BannerPNG from 'assets/images/CollectionDetails/banner.png'
import { RiFileCopyLine } from 'react-icons/ri'
import { BiLinkExternal } from 'react-icons/bi'
import { IPool } from 'interfaces'
import {
  DetailWrapper,
  BannerImg,
  LogoWrapper,
  InfoWrapper,
  InfoTitle,
  CollectionName,
  CollectionAddress,
} from './Styled'
import { useCopyClipboard } from 'hooks'
import { getExplorerLink } from 'utils/utils'

function CollectionDetails(): JSX.Element {
  const { id } = useParams()
  const [pool, setPool] = useState<IPool>()
  const [nfts, setNFTs] = useState<any>([])
  const [empty, setEmpty] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [isCopied, setCopied] = useCopyClipboard()

  const url = pool
    ? `https://firebasestorage.googleapis.com/v0/b/omni-live.appspot.com/o/store%2F${pool.nft_token}-header?alt=media`
    : BannerPNG

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const _pool = await NearFTSDK.getPoolInfo(process.env.REACT_APP_NETWORK_ID ?? 'testnet', CONTRACT_ID, Number(id))

      if (!_pool) {
        setEmpty(true)
      } else {
        setPool(_pool)
        setLoading(false)
      }
    })()
  }, [id])

  useEffect(() => {
    setLoading(true)
    if (pool && id) {
      const _tokenIds = pool.pool_token_ids
      const _poolToken = pool.poolTokenMetadata

      const _nfts: any = []

      _tokenIds.map(tokenId =>
        _nfts.push({
          ..._poolToken[tokenId],
          id: `${tokenId}-${_poolToken[tokenId].contractId}`,
          price: pool.spot_price,
          poolId: pool.pool_id,
        }),
      )
      setNFTs(
        _nfts.sort(function (a: { tokenId: any }, b: { tokenId: any }) {
          return Number(a.tokenId) - Number(b.tokenId)
        }),
      )
      setLoading(false)
    }
  }, [id, pool])

  if (isLoading) return <Loader />

  if (empty)
    return (
      <div className="my-20">
        <h2 className="font-poppins dark:text-white text-nft-black-1 text-center font-semibold text-2xl minlg:text-3xl">
          Not found
        </h2>
      </div>
    )

  return (
    <Layout>
      <Container sx={{ minHeight: '90vh' }}>
        <DetailWrapper>
          <BannerImg
            src={url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = BannerPNG
            }}
            alt={pool.nft_token}
          />
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <LogoWrapper>
              <CollectionLogo poolName={pool.nft_token} size="9.375rem" borderRadius="0" />
            </LogoWrapper>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '2.5rem' }}>
            <CollectionName variant="h1">{pool.nft_token}</CollectionName>
            <Grid container gap={1} sx={{ alignItems: 'center', width: 'fit-content', marginX: 'auto' }}>
              <Tooltip title={isCopied ? 'Copied' : 'Copy'} arrow placement="top">
                <CollectionAddress onClick={() => setCopied(pool.nft_token)}>
                  <span>{pool.nft_token}&nbsp;</span>
                  <RiFileCopyLine fill="#fff" size={26} />
                </CollectionAddress>
              </Tooltip>
              <Tooltip title="View on explorer" arrow placement="top">
                <Link
                  href={`${getExplorerLink(pool.nft_token, 'contract')}`}
                  target="_blank"
                  sx={{ color: '#fff', '&:hover': { color: '#ef4a31' } }}
                >
                  <BiLinkExternal size={26} />
                </Link>
              </Tooltip>
            </Grid>
          </Grid>

          <InfoWrapper>
            <Grid
              container
              columns={{ xs: 9 }}
              sx={{ marginTop: '4rem !important', textAlign: 'center', alignItems: 'end' }}
            >
              <Grid item xs={3}>
                <InfoTitle>Floor Price</InfoTitle>
                <Price price={pool.spot_price} alignment="center" fontSize="1rem" />
              </Grid>
              <Grid item xs={3}>
                <InfoTitle>Offer TVL</InfoTitle>
                <Price price={pool.near_balance} alignment="center" fontSize="1rem" />
              </Grid>
              <Grid item xs={3}>
                <InfoTitle>Volume</InfoTitle>
                <Price price={pool.accumulated_volume} alignment="center" fontSize="1rem" />
              </Grid>
            </Grid>
          </InfoWrapper>

          {nfts.length > 0 && (
            <Grid container spacing={4} sx={{ my: '2.5rem', justifyContent: 'center' }}>
              {nfts.map(nft => (
                <Grid item xs={12} md={4} lg={3} key={nft.id}>
                  <NFTBox nft={nft} onSale={true} />
                </Grid>
              ))}
            </Grid>
          )}
        </DetailWrapper>
      </Container>
    </Layout>
  )
}

export default CollectionDetails
