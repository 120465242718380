const typography = {
  allVariants: {
    color: '#ffffff',
    transition: 'all 0.5s ease',
  },
  fontFamily: ['Poppins', 'sans-serif'].join(','),
  h1: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    fontSize: '2rem',
    fontWeight: 700,
    marginBottom: '1rem',
    '@media (min-width: 75rem)': { fontSize: '3.5rem' },
  },
  h2: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '38px',
    marginBottom: '1rem',
  },
  h3: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1,
    marginBottom: '1rem',
    textOverflow: 'ellipsis',
  },
  h4: {
    fontSize: '1.25rem',
    fontWeight: 700,
    marginBottom: '1rem',
  },
  h5: {
    fontSize: '1rem',
    fontWeight: 700,
    marginBottom: '1rem',
  },
  h6: {
    fontSize: 18,
    fontWeight: 400,
  },
  body1: {
    marginBottom: '0.5rem',
    fontSize: '1rem',
    lineHeight: 1.5,
    fontWeight: 500,
  },
  body2: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  subtitle1: {
    marginBottom: '6px',
    color: '#ffffff',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    fontWeight: 600,
  },
  subtitle2: {
    marginBottom: '6px',
    fontSize: '1.1rem',
    lineHeight: 1.5,
    fontWeight: 700,
  },
}

export default typography
