import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import NAV_ITEMS from './items'
import { MenuA, MenuItem, MenuLink, SubText } from './Styled'

function DesktopNav(): JSX.Element {
  const location = useLocation()
  const { pathname } = location
  let realPathName = pathname.substring(1)

  if (pathname == '/') {
    realPathName = 'home'
  }

  return (
    <Box>
      {NAV_ITEMS.map(navItem => (
        <MenuItem key={navItem.label}>
          {navItem.href && (
            <MenuA href={navItem.href} target={navItem.target ?? '_self'}>
              {navItem.label}
            </MenuA>
          )}
          {navItem.to && (
            <MenuLink to={navItem.to} className={`${realPathName.includes(navItem.slug ?? '') ? 'active' : ''}`}>
              {navItem.label}
            </MenuLink>
          )}
          {navItem.subLabel && <SubText>{navItem.subLabel}</SubText>}
        </MenuItem>
      ))}
    </Box>
  )
}

export default DesktopNav
