import { Button, Table, TableBody, TableContainer, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Layout from 'components/Layout'
import { CONTRACT_ID } from '../../constants'
import { IPool } from 'interfaces'
import { useEffect, useState } from 'react'
import { useAccount } from 'state/wallet/hooks'
import NearFTSDK from 'nearft-sdk'
import { toRoundedReadableNumber } from 'utils/number'
import { Data, getComparator, Order } from 'components/Table/sorting'
import EnhancedTableHead from 'components/Table/TableHead'
import TableRow from 'components/Table/TableRow'

function YourPools(): JSX.Element {
  const account = useAccount()
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof Data>('name')
  const [isLoading, setLoading] = useState(false)
  const [rows, setRows] = useState<Data[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      setLoading(true)
      const _pools: IPool[] = await NearFTSDK.getPools(process.env.REACT_APP_NETWORK_ID ?? 'testnet', CONTRACT_ID)
      const _yourPools = _pools.filter(p => p.owner === account)
      const _rows: Data[] = []

      for (let i = 0; i < _yourPools.length; i++) {
        const pool = _pools[i]
        _rows.push({
          poolId: pool.pool_id,
          name: pool.nft_token,
          listing: pool.pool_token_ids.length,
          floorPrice: Number(
            toRoundedReadableNumber({
              decimals: 24,
              number: pool.spot_price,
              precision: 3,
            }),
          ),
          liquidity: Number(
            toRoundedReadableNumber({
              decimals: 24,
              number: pool.near_balance,
              precision: 3,
            }),
          ),
          volume: Number(
            toRoundedReadableNumber({
              decimals: 24,
              number: pool.accumulated_volume,
              precision: 3,
            }),
          ),
        })
      }
      setRows(_rows)
      setLoading(false)
    })()
  }, [account])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <Layout>
      <Typography
        variant="h1"
        sx={{
          mb: '0.5rem',
        }}
      >
        Pools Overview
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ color: '#fcfcfd', fontWeight: 500, fontSize: '16px', lineHeight: '24px', opacity: 0.8, mb: '2rem' }}
      >
        <span>View all of the pools that </span>
        {account ? <span style={{ color: '#ef4a31' }}>{account}</span> : <span>you</span>}
        <span> provides liquidity for.</span>
      </Typography>

      <Button variant="contained" onClick={() => navigate('/create')}>
        Create New Pool
      </Button>

      <TableContainer sx={{ marginTop: '2.5rem' }} aria-labelledby="tableTitle">
        <Table>
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} isLoading={isLoading} />
          <TableBody
            sx={{
              '@media (min-width: 768px) and (max-width: 991px)': {
                display: 'flex',
                gap: '1rem',
              },
            }}
          >
            {rows.sort(getComparator(order, orderBy)).map(row => (
              <TableRow key={row.poolId} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  )
}

export default YourPools
