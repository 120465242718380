import styled from 'styled-components/macro'

const AcceptedFormatText = styled.p`
  margin-bottom: 30px;
  text-align: center;
  color: #ffffff;
`

const AcceptedFormats = ({
  acceptedFormats,
  idealDimensions,
  maxSize,
}: {
  acceptedFormats: string[]
  idealDimensions: string
  maxSize: number
}) => {
  return (
    <AcceptedFormatText>
      {acceptedFormats && (
        <>
          Accepted Formats:{' '}
          {/*
            // @ts-ignore */}
          <span>{[...new Set(acceptedFormats)].join(', ')}</span> {(idealDimensions || maxSize) && <>|</>}{' '}
        </>
      )}
      {idealDimensions && (
        <>
          Ideal dimension: <span>{idealDimensions}</span> {maxSize && <>|</>}{' '}
        </>
      )}
      {maxSize && (
        <>
          Max size: <span>{maxSize}mb</span>
        </>
      )}
    </AcceptedFormatText>
  )
}

export default AcceptedFormats
