import { AppBar, Menu } from '@mui/material'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

export const HeaderWrapper = styled(AppBar)`
  height: 4.5rem;
`
export const DesktopLogo = styled.img`
  display: none;

  @media (min-width: 48rem) {
    display: block;
    width: 150px;
  }

  @media (min-width: 1024px) {
    display: block;
    width: 200px;
  }
`
export const MobileLogo = styled.img`
  width: 30px;
  margin-right: 1rem;

  @media (min-width: 48rem) {
    display: none;
  }
`
export const MenuItem = styled.span`
  position: relative;
  padding: 0 0.5rem;
`
export const MenuLinkStyle = css`
  padding: 27px 1rem;
  position: relative;
  font-family: Poppins, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  color: #fcfcfd;

  &::before {
    content: '',
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    transition: all 400ms ease;
    background-color: ${props => props.theme.primary};
  }

  &:hover,
  &.active {
    background-image: linear-gradient(90deg, #f37b4a 0%, #ed3024 99.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-shadow: inset 0px -4px 0px #ef4a31;
    background-color: ${props => props.theme.primary};
  }
`
export const MenuLink = styled(Link)`
  ${MenuLinkStyle}
`
export const MenuA = styled.a`
  ${MenuLinkStyle}
`
export const SubText = styled.span`
  padding: 5px;
  border-radius: 3px;
  position: absolute;
  right: 0;
  top: -25px;
  width: fit-content;
  font-size: 7px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: #fff;
  background-color: ${props => props.theme.primary};
`
export const MobileMenuWrapper = styled(Menu)`
  li {
    min-height: 2rem;

    &:hover a {
      background-image: linear-gradient(90deg, #f37b4a 0%, #ed3024 99.98%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`
export const MobileMenuLinkStyle = css`
  display: block;
  font-weight: 500;
  color: #000;
`
export const MobileMenuItem = styled.li`
  margin: 0;
`
export const MobileMenuLink = styled(Link)`
  ${MobileMenuLinkStyle}
`
export const MobileMenuA = styled.a`
  ${MobileMenuLinkStyle}
`
export const MobileSubText = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  padding: 5px;
  border-radius: 3px;
  width: fit-content;
  font-size: 7px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: #fff;
  background-color: ${props => props.theme.primary};
`

// Account Button
export const AccountMenuWrapper = styled(Menu)`
  li {
    min-height: 2rem;

    &:hover span {
      background-image: linear-gradient(90deg, #f37b4a 0%, #ed3024 99.98%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`
export const AccountItem = styled.span`
  font-weight: 500;
  color: #000;

  &:hover {
    background-image: linear-gradient(90deg, #f37b4a 0%, #ed3024 99.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

// Cart
export const CartItemImage = styled.img`
  width: 100%;
  height: 5rem;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: top;
`
