import { useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import NearFTSDK from 'nearft-sdk'
import { SelectAll } from '../Styled'
import { useSelectedNFTCollection, useSelectedNFTs } from 'state/pool/hooks'
import NFTCollectionSelect from '../components/NFTCollectionSelect'
import { CONTRACT_ID } from '../../../constants'
import { useAccount } from 'state/wallet/hooks'
import Loader from 'components/Loader'
import NFTItem from '../components/NFTItem'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { updateSelectedNFTs } from 'state/pool/actions'
import { HiOutlineCheck } from 'react-icons/hi'
import { FiChevronRight } from 'react-icons/fi'

function Step3({ onBack, onNext }: { onBack: () => void; onNext: () => void }): JSX.Element {
  const account = useAccount()
  const selectedNFTs = useSelectedNFTs()
  const selectedCollection = useSelectedNFTCollection()

  const dispatch = useDispatch<AppDispatch>()

  const [isLoading, setLoading] = useState(false)
  const [nfts, setNFTs] = useState<any>([])
  const [isSelectAll, setSelectAll] = useState(false)

  const MAX_ITEMS = 10

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      if (selectedCollection) {
        setLoading(true)
        const _list = await NearFTSDK.getNFTData(process.env.REACT_APP_NETWORK_ID ?? 'testnet', account, CONTRACT_ID)
        setNFTs(_list.flat(1).filter(n => n.contractId === selectedCollection))
        setLoading(false)
      }
    })()
  }, [selectedCollection])

  const onSelectAll = () => {
    if (isSelectAll) {
      dispatch(updateSelectedNFTs({ selectedNFTs: [] }))
    } else {
      dispatch(updateSelectedNFTs({ selectedNFTs: nfts }))
    }
    setSelectAll(!isSelectAll)
  }

  return (
    <Grid container justifyContent="center" spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mt: 2, mb: 0 }}>
          Step 2/3: Selecting assets
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: { xs: '1rem', md: '1.5rem' } }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <NFTCollectionSelect />
            <Grid item sx={{ mt: { xs: '1rem', sm: 0 } }}>
              {selectedNFTs && (
                <>
                  <Typography variant="h5" sx={{ mb: 0 }}>
                    {selectedNFTs.length} {selectedNFTs.length > 1 ? 'items' : 'item'} selected
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>

          {/* NFT Grid */}
          <Grid container sx={{ mt: '2rem', width: 'auto', '@media (min-width: 48rem)': { mx: '-15px' } }}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {selectedCollection ? (
                  <>
                    {selectedNFTs && nfts.length > 0 ? (
                      <>
                        {nfts.map(nft => (
                          <NFTItem
                            key={nft.tokenId}
                            nft={nft}
                            isSelected={selectedNFTs.findIndex(n => n.tokenId == nft.tokenId) >= 0}
                          />
                        ))}
                      </>
                    ) : (
                      <Typography paddingX="15px">No NFTs Found.</Typography>
                    )}
                  </>
                ) : (
                  <Typography paddingX="15px">Please select an NFT collection.</Typography>
                )}
              </>
            )}
          </Grid>

          {/* Footer */}
          <Grid container alignItems="center" justifyContent="space-between" sx={{ mt: '1rem' }}>
            {selectedCollection && (
              <>
                <SelectAll onClick={onSelectAll} className={isSelectAll ? 'is-selected' : ''}>
                  <HiOutlineCheck />
                  <Typography>Select All</Typography>
                </SelectAll>
                <Grid>
                  <Button variant="text" onClick={onBack}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    onClick={onNext}
                    disabled={selectedNFTs && (selectedNFTs?.length <= 0 || selectedNFTs?.length > MAX_ITEMS)}
                  >
                    Next
                    <FiChevronRight />
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Step3
