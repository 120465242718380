import { FormProvider, useForm } from 'react-hook-form'
import { EInputType } from '../../constants'
import Layout from 'components/Layout'
import { CreateStore } from './components/CreateStoreForm'
import { Typography } from '@mui/material'
import React from 'react'

export default function NewStore() {
  const methods = useForm({
    defaultValues: {
      [EInputType.NAME]: '',
      [EInputType.SYMBOL]: '',
    },
  })

  return (
    <Layout>
      <Typography
        variant="h1"
        sx={{
          mb: '0.5rem',
        }}
      >
        New Store
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ color: '#fcfcfd', fontWeight: 500, fontSize: '16px', lineHeight: '24px', opacity: 0.8, mb: '2rem' }}
      >
        Let‘s create your store
      </Typography>
      <FormProvider {...methods}>
        <form>
          <CreateStore />
        </form>
      </FormProvider>
    </Layout>
  )
}
