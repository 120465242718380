import { Typography } from '@mui/material'
import styled from 'styled-components/macro'

export const NFTImage = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`

export const InfoTitle = styled(Typography)`
  background: linear-gradient(90deg, #f37b4a 0%, #ed3024 99.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  word-break: break-word;
  margin-bottom: 1.5rem !important;
`
