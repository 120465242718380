import { Button, IconButton, MenuItem, useMediaQuery, useTheme } from '@mui/material'
import { BiWalletAlt } from 'react-icons/bi'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { useAccount, useWalletSelector } from 'state/wallet/hooks'
import { AccountItem, AccountMenuWrapper } from './Styled'
import { updateAccount } from 'state/wallet/actions'

function AccountButton(): JSX.Element {
  const { selector, modal, accounts, accountId } = useWalletSelector()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const account = useAccount()
  const dispatch = useDispatch<AppDispatch>()

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const onOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const onCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const onSignIn = async () => {
    modal.show()
  }

  const onSignOut = async () => {
    const wallet = await selector.wallet()

    wallet
      .signOut()
      .then(() => {
        dispatch(updateAccount({ account: '' }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const onSwitchWallet = () => {
    modal.show()
  }

  const handleSwitchAccount = () => {
    const currentIndex = accounts.findIndex(x => x.accountId === accountId)
    const nextIndex = currentIndex < accounts.length - 1 ? currentIndex + 1 : 0

    const nextAccountId = accounts[nextIndex].accountId

    selector.setActiveAccount(nextAccountId)

    dispatch(updateAccount({ account: nextAccountId }))

    alert('Switched account to ' + nextAccountId)
  }

  return (
    <>
      {account ? (
        <>
          {isMobile ? (
            <IconButton arial-label={accountId} onClick={onOpenUserMenu} sx={{ color: '#ffffff' }}>
              <BiWalletAlt />
            </IconButton>
          ) : (
            <Button variant="contained" onClick={onOpenUserMenu}>
              {accountId}
            </Button>
          )}
          <AccountMenuWrapper
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={onCloseUserMenu}
          >
            {accounts.length > 1 && (
              <MenuItem onClick={handleSwitchAccount}>
                <AccountItem>Switch Account</AccountItem>
              </MenuItem>
            )}
            <MenuItem onClick={onSwitchWallet}>
              <AccountItem>Switch Wallet</AccountItem>
            </MenuItem>
            <MenuItem onClick={onSignOut}>
              <AccountItem>Sign Out</AccountItem>
            </MenuItem>
          </AccountMenuWrapper>
        </>
      ) : (
        <Button variant="contained" color="primary" onClick={onSignIn}>
          Connect
        </Button>
      )}
    </>
  )
}

export default AccountButton
