import { createTheme } from '@mui/material/styles'
import components from './components'
import typography from './typography'
import palette from './palette'

const theme = createTheme({
  typography,
  // @ts-ignore
  palette,
  components,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 992,
      lg: 1200,
      xl: 1400,
    },
  },
  primary: '#ef4a31',
  secondary: '#4bc9f0',
  headerBg: '#1f1f1f',
  footerBg: '#000000',
  green: '#27ae60',
  red: '#ff6871',
  blue: '#1abcfe',
  purple: '#a259ff',
})

export default theme
