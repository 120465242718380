// ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useCallback, useEffect } from 'react'
import { setupModal } from '@near-wallet-selector/modal-ui'
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet'
import { setupNearWallet } from '@near-wallet-selector/near-wallet'
import { setupNearFi } from '@near-wallet-selector/nearfi'
import { setupSender } from '@near-wallet-selector/sender'
import { CONTRACT_ID, MAINNET_CONFIG, TESTNET_CONFIG } from '../constants'
import { useDispatch, useSelector } from 'react-redux'
import { updateAccount, updateAcounts, updateWalletSelector } from 'state/wallet/actions'
import { AppState } from 'state'
import { distinctUntilChanged, map } from 'rxjs'
import { setupWalletSelector } from '@near-wallet-selector/core'
import NearfiIcon from 'assets/images/nearfi-icon.png'

function Web3ReactManager({ children }: { children: JSX.Element }): JSX.Element | null {
  const dispatch = useDispatch()
  const walletSelector = useSelector((state: AppState) => state.wallet.selector)

  let networkError = false

  const init = useCallback(async () => {
    const _selector = await setupWalletSelector({
      network: process.env.REACT_APP_NETWORK_ID === 'testnet' ? TESTNET_CONFIG : MAINNET_CONFIG,
      debug: true,
      modules: [setupNearWallet(), setupMyNearWallet(), setupSender(), setupNearFi({ iconUrl: NearfiIcon })],
    })

    const _modal = setupModal(_selector, { contractId: CONTRACT_ID })
    const state = _selector.store.getState()

    window.selector = _selector
    window.modal = _modal

    dispatch(
      updateWalletSelector({
        selector: {
          selector: _selector,
          modal: _modal,
          accounts: state.accounts,
          accountId: state.accounts.find(account => account.active)?.accountId || null,
        },
      }),
    )
    dispatch(updateAccount({ account: state.accounts.find(account => account.active)?.accountId || '' }))
  }, [])

  useEffect(() => {
    init().catch(err => {
      networkError = true
      console.error(err)
      console.error('Failed to initialise wallet selector')
    })
  }, [init])

  useEffect(() => {
    if (!walletSelector) {
      return
    }

    const subscription = walletSelector.selector.store.observable
      .pipe(
	//@ts-ignore
        map(state => state.accounts),
        distinctUntilChanged(),
      )
      .subscribe(nextAccounts => {
        dispatch(
          updateAcounts({
            accounts: nextAccounts,
          }),
        )
      })

    return () => subscription.unsubscribe()
  }, [walletSelector])

  if (!walletSelector || !walletSelector.modal) {
    return null
  }

  return networkError ? null : children
}

export default Web3ReactManager
