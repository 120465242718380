import { Typography } from '@mui/material'
import Layout from 'components/Layout'
import { useState } from 'react'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

function CreatePool(): JSX.Element {
  const [step, setStep] = useState(1)

  return (
    <Layout>
      <Typography
        variant="h1"
        sx={{
          mb: '0.5rem',
        }}
      >
        Create Pool
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ color: '#fcfcfd', fontWeight: 500, fontSize: '16px', lineHeight: '24px', opacity: 0.8, mb: '2rem' }}
      >
        Provide liquidity to buy, sell, or trade NFTs on your behalf.
      </Typography>
      {step === 1 && <Step1 onNext={() => setStep(2)} />}
      {step === 2 && <Step2 onBack={() => setStep(1)} onNext={() => setStep(3)} />}
      {step === 3 && <Step3 onBack={() => setStep(2)} onNext={() => setStep(4)} />}
    </Layout>
  )
}

export default CreatePool
