import { Box, Slider, Typography, useMediaQuery, useTheme } from '@mui/material'
import NumberInput from 'components/NumberInput'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { PoolCurve, updatePoolParams } from 'state/pool/actions'
import { usePoolParams } from 'state/pool/hooks'
import NFTCollectionSelect from './NFTCollectionSelect'

function AssetsFormSell(): JSX.Element {
  const poolParams = usePoolParams()
  const dispatch = useDispatch<AppDispatch>()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [totalNEAR, setTotalNEAR] = useState(0)
  const [nftAmount, setNFTAmount] = useState(0)

  const { curve, delta, startPrice, sellNFTAmount, sellNEARAmount } = poolParams

  const calculateNEAR = (_amount: number): number => {
    if (curve === PoolCurve.EXPONENTIAL) {
      const _delta = 1 + delta / 100
      return delta > 0 ? startPrice * (1 + (_delta - _delta ** _amount) / (1 - _delta)) : startPrice * _amount
    }
    return startPrice * _amount + (((_amount - 1) * _amount) / 2) * delta
  }

  useEffect(() => {
    dispatch(
      updatePoolParams({
        poolParams: {
          ...poolParams,
          sellNEARAmount: calculateNEAR(sellNFTAmount),
        },
      }),
    )
  }, [curve, delta, startPrice, sellNFTAmount])

  const onAssetsAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _amount = Number(event.target.value)

    if (_amount < nftAmount) {
      setNFTAmount(_amount)
      setTotalNEAR(calculateNEAR(_amount))
    } else {
      setNFTAmount(nftAmount)
      setTotalNEAR(calculateNEAR(nftAmount))
    }

    dispatch(
      updatePoolParams({
        poolParams: {
          ...poolParams,
          sellNFTAmount: _amount,
        },
      }),
    )
  }

  const onSlideChange = (event: Event, value: number | number[]) => {
    const _nftAmounts = value as number
    setNFTAmount(_nftAmounts)
    setTotalNEAR(calculateNEAR(_nftAmounts))
  }

  return (
    <>
      <Box
        sx={{
          mt: '2rem',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 500 }}>
          If you want to sell
        </Typography>
        <NumberInput
          label=""
          description=""
          defaultValue={0}
          showFloorPrice={true}
          validWhen={sellNFTAmount >= 0}
          invalidMessage={'Must be greater than 0'}
          isCollectionInput={true}
          adorment={!isMobile && <NFTCollectionSelect />}
          onChange={onAssetsAmountChange}
        />
        {isMobile && <NFTCollectionSelect />}
      </Box>
      <Box>
        <Typography variant="h4" textAlign="center" sx={{ mt: { xs: '1rem', lg: 0 }, mb: '1.5rem', fontWeight: 500 }}>
          you will earn <strong>{Number.parseFloat(sellNEARAmount.toFixed(3))}</strong> NEAR total
        </Typography>
      </Box>
      <hr />
      <Box sx={{ textAlign: 'center' }}>
        <Typography marginTop="1.5rem">
          Selling {nftAmount} {nftAmount > 1 ? 'NFTs' : 'NFT'}...
        </Typography>
        <Slider
          disabled={sellNFTAmount === 0}
          defaultValue={0}
          step={1}
          min={0}
          max={sellNFTAmount}
          onChange={onSlideChange}
          sx={{ width: '60%', marginX: 'auto', display: 'block' }}
        />
        <Typography marginTop="0.5rem">
          will earn you <strong>{Number.parseFloat(totalNEAR.toFixed(3))}</strong> NEAR
        </Typography>
      </Box>
    </>
  )
}

export default AssetsFormSell
