import { Box, Button, Grid, IconButton, Menu, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { FiChevronDown } from 'react-icons/fi'
import { BsArrowUpRight } from 'react-icons/bs'
import { BiTrendingUp } from 'react-icons/bi'
import { PoolCurve } from 'state/pool/actions'
import { usePoolParams } from 'state/pool/hooks'
import BondingCurveItem from './BondingCurveItem'

function BondingCurveSelect(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const poolParams = usePoolParams()
  const curve = (poolParams && poolParams.curve) ?? ''

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box marginBottom="0.75rem">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid container alignItems="center" sx={{ mb: '0.5rem' }}>
          <Typography sx={{ fontWeight: '600', mb: 0, mr: '0.5rem' }}>Bonding Curve</Typography>
          <Tooltip title="Controls how your pool's price will change." placement="top" arrow>
            <IconButton sx={{ p: 0 }}>
              <AiOutlineQuestionCircle size={16} color="#fff" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        onClick={handleClick}
        endIcon={<FiChevronDown />}
        sx={{
          textTransform: 'none',
          background: 'rgba(255, 255, 255, 0.2)',
          padding: { xs: '0.5rem', md: '0.5rem 1rem' },
          marginBottom: '0.5rem',
          '&:hover': { background: 'rgba(255, 255, 255, 0.3)' },
        }}
      >
        <Grid container alignItems="center" sx={{ cursor: 'pointer' }}>
          {curve === PoolCurve.LINEAR ? <BsArrowUpRight size={24} /> : <BiTrendingUp size={24} />}
          <Typography sx={{ mb: 0, ml: '0.5rem' }}>
            {curve == PoolCurve.LINEAR ? 'Linear Curve' : 'Exponential Curve'}
          </Typography>
        </Grid>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <BondingCurveItem
          curve={PoolCurve.LINEAR}
          isSelected={poolParams?.curve === PoolCurve.LINEAR}
          onSelect={handleClose}
        />
        <BondingCurveItem
          curve={PoolCurve.EXPONENTIAL}
          isSelected={poolParams?.curve === PoolCurve.EXPONENTIAL}
          onSelect={handleClose}
        />
      </Menu>
    </Box>
  )
}

export default BondingCurveSelect
