import { floor } from 'mathjs'
import { Box, Button, Grid, Typography } from '@mui/material'
import AssetsAmountsText from '../components/AssetsAmountsText'
import PriceForm from '../components/PriceForm'
import AssetsFormBuy from '../components/AssetsFormBuy'
import AssetsFormSell from '../components/AssetsFormSell'
import AssetsFormBoth from '../components/AssetsFormBoth'
import { FiChevronRight } from 'react-icons/fi'
import { useEffect, useState } from 'react'
import { usePoolParams } from 'state/pool/hooks'
import { PoolCurve, PoolType, resetPoolParams } from 'state/pool/actions'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'

function Step2({ onBack, onNext }: { onBack: () => void; onNext: () => void }): JSX.Element {
  const poolParams = usePoolParams()
  const [formValid, setFormValid] = useState(false)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    let _isValid = poolParams.startPrice > 0 && poolParams.buyNFTAmount > 0 && poolParams.delta >= 0

    if (_isValid) {
      if (poolParams.curve === PoolCurve.EXPONENTIAL) {
        _isValid = poolParams.delta > 0
      } else {
        const maxNFTAmount = floor(poolParams.startPrice / poolParams.delta)
        _isValid = poolParams.buyNFTAmount <= maxNFTAmount
      }
    }

    setFormValid(_isValid)
  }, [poolParams])

  const backToStep1 = () => {
    dispatch(resetPoolParams())
    onBack()
  }

  return (
    <Grid container justifyContent="center" spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mt: 2, mb: 0 }}>
          Step 2/3: Configuring Pool Parameters
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: { xs: '1rem', md: '1.5rem' } }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={5}>
              <Typography variant="h3" sx={{ mb: 0, fontSize: '1.5rem' }}>
                Pool Pricing
              </Typography>
              <Typography sx={{ mt: '0.5rem', mb: '2rem', color: 'rgba(252, 253, 253, 0.8)' }}>
                Set the initial price and how your pool's price changes.
              </Typography>
              <PriceForm />
            </Grid>

            {/* Separator */}
            {/* <Grid item xs={12} md={1}>
              <Box
                sx={{
                  my: { xs: 3, md: 0 },
                  height: { xs: '1px', md: '100%' },
                  width: { xs: '100%', md: '1px' },
                  backgroundColor: '#ffffffab',
                }}
              />
            </Grid> */}

            <Grid item xs={12} md={5} lg={6}>
              <Typography variant="h3" sx={{ mt: { xs: '3rem', md: 0 }, mb: 0, fontSize: '1.5rem' }}>
                Asset Amounts
              </Typography>
              <AssetsAmountsText />
              {poolParams.poolType === PoolType.BUY && <AssetsFormBuy />}
              {poolParams.poolType === PoolType.SELL && <AssetsFormSell />}
              {poolParams.poolType === PoolType.BOTH && <AssetsFormBoth />}
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" sx={{ mt: '2rem' }}>
            <Button variant="text" onClick={backToStep1}>
              Back
            </Button>
            <Button variant="contained" onClick={onNext} disabled={!formValid}>
              Next
              <FiChevronRight />
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Step2
