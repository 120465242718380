import { createReducer } from '@reduxjs/toolkit'
import { IWalletSelector } from 'interfaces'
import { updateAccount, updateAcounts, updateWalletSelector } from './actions'

export interface WalletState {
  readonly selector?: IWalletSelector
  readonly account?: string
}

export const initialState: WalletState = {
  selector: undefined,
  account: undefined,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateWalletSelector, (state, action) => {
      state.selector = action.payload.selector
    })
    .addCase(updateAcounts, (state, action) => {
      if (state.selector !== undefined) {
        state.selector.accounts = action.payload.accounts
      }
    })
    .addCase(updateAccount, (state, action) => {
      state.account = action.payload.account
    }),
)
