import { Box, Grid, Link, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { IPool } from 'interfaces'
import styled from 'styled-components/macro'
import Price from 'components/Price'
import CollectionLogo from 'components/CollectionLogo'

const CardInner = styled.div`
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  background-color: rgba(1, 10, 25, 0.3);
  padding: 1rem;
  width: calc(100% - 0.5rem);

  @media (min-width: 48rem) {
    padding: 2.5rem;
  }
`
const StyledCollectionLogo = styled(CollectionLogo)`
  margin-bottom: 1rem;
`
const Separator = styled.div`
  margin: 0 auto;
  width: 80%;
  height: 0.5rem;
  border: 1px solid ${props => props.theme.blue};
  border-bottom: 0;
`

function CollectionCard({ pool }: { pool: IPool }): JSX.Element {
  return (
    <Box
      sx={{
        marginX: 'auto',
        '@media (min-width: 425px)': { width: '20rem' },
        '@media (min-width: 36rem) and (max-width: 767px)': { width: 'auto' },
      }}
    >
      <Link component={RouterLink} to={`/collections/${pool.pool_id}`}>
        <CardInner>
          <StyledCollectionLogo poolName={pool.nft_token} />
          <Typography
            variant="h3"
            sx={{
              fontSize: '1.25rem',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              margin: '0',
              pb: '1.5rem',
              '@media (min-width: 48rem)': {
                fontSize: '1.5rem',
              },
            }}
          >
            {pool.nft_token}
          </Typography>
          <Separator />
          <Grid container justifyContent="space-between" sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Grid item xs={5}>
              <Typography
                variant="body1"
                sx={{
                  color: '#ff7262',
                }}
              >
                Floor Price
              </Typography>
              <Price price={pool.spot_price} />
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant="body1"
                sx={{
                  color: '#1abcfe',
                  textAlign: 'right',
                }}
              >
                Liquidity
              </Typography>
              <Price price={pool.near_balance} alignment="right" />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" sx={{ marginTop: '1rem' }}>
            <Grid item xs={5}>
              <Typography
                variant="body1"
                sx={{
                  color: '#a259ff',
                }}
              >
                Listings
              </Typography>
              <Typography sx={{ lineHeight: 2 }} variant="body2">
                {pool.pool_token_ids.length}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant="body1"
                sx={{
                  color: '#a259ff',
                  textAlign: 'right',
                }}
              >
                Volume
              </Typography>
              <Price price={pool.accumulated_volume} alignment="right" />
            </Grid>
          </Grid>
        </CardInner>
      </Link>
    </Box>
  )
}

export default CollectionCard
