import { Typography } from '@mui/material'
import styled from 'styled-components/macro'

export const DetailWrapper = styled.div`
  padding-top: 7rem;

  @media (min-width: 36rem) {
    padding-top: 5rem;
  }

  @media (min-width: 62rem) {
    padding-top: 6rem;
  }
`
export const BannerImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  max-height: 15rem;
  object-fit: cover;

  @media (min-width: 120rem) {
    width: 100vw;
  }
`
export const CollectionName = styled(Typography)`
  font-size: 1.75rem !important;
  word-break: break-word;

  @media (min-width: 62rem) {
    font-size: 2.5rem !important;
  }
`
export const CollectionAddress = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  text-align: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  color: #4bc9f0;
  cursor: pointer;
  word-break: break-all;
  transition: all 0.5s ease;

  svg {
    transition: all 0.5s ease;
  }

  &:hover {
    color: #ef4a31;

    svg {
      fill: #ef4a31;
    }
  }
`
export const LogoWrapper = styled.div`
  position: relative;
  border: 3px solid #fff;
  border-radius: 2rem;
  overflow: hidden;
  width: fit-content;
  margin: 0 auto;
  z-index: 1;
`
export const InfoWrapper = styled.div`
  @media (min-width: 48rem) {
    padding: '0 5rem';
  }

  @media (min-width: 120rem) {
    padding: '0 10rem';
  }
`
export const InfoTitle = styled.p`
  background: linear-gradient(90deg, #f37b4a 0%, #ed3024 99.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
`
export const NFTImage = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`
