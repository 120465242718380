/* eslint-disable @typescript-eslint/no-extra-semi */
import { IAccount, IWalletSelector } from 'interfaces'
import { providers } from 'near-api-js'
import { AccountView } from 'near-api-js/lib/providers/provider'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'state'

export const useWalletSelector = (): IWalletSelector => {
  const currentSelector = useSelector((state: AppState) => state.wallet.selector)
  return useMemo(() => currentSelector, [currentSelector])
}

export const useChainId = (): number => {
  const walletSelector = useWalletSelector()
  const chainId = walletSelector
    ? walletSelector.selector.options.network.networkId.includes('testnet')
      ? 7869658284698384
      : 78696582
    : 7869658284698384
  return chainId
}

export const useAccount = (): string => {
  const account = useSelector((state: AppState) => state.wallet.account)
  return useMemo(() => account, [account])
}
