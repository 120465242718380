// Import level 1
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { mbjs } from '@mintbase-js/sdk'
import styled from 'styled-components/macro'

// Import Components
import Header from './components/Header'
import Footer from './components/Footer'

// Import Pages
import Home from 'pages/Home'
import Collections from 'pages/Collections'
import CollectionDetails from 'pages/CollectionDetails'
import InventoryCollectionDetails from 'pages/CollectionDetails/InventoryCollectionDetails'
import OnSaleNFTDetails from 'pages/NFTDetails/OnSaleNFTDetails'
import NFTDetails from 'pages/NFTDetails/NFTDetails'
import CreatePool from './pages/CreatePool'
import YourPools from './pages/YourPools'
import YourNFTs from './pages/YourNFTs'
import MintNFT from './pages/MintNFT'
import CreateStore from './pages/CreateStore'
import SuccessPage from './pages/Success'

// Import Assets
import BackgroundImagePNG from 'assets/images/bg.png'

const PageContainer = styled.div`
  position: relative;
  background-color: #00000f;
  background-image: url(${BackgroundImagePNG});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const AppRoutes: React.FC = () => {
  mbjs.config({
    network: 'testnet',
    callbackUrl: '',
    contractAddress: '',
  })
  return (
    <BrowserRouter>
      <PageContainer>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/collections/:id" element={<CollectionDetails />} />
          <Route path="/collections/:poolId/:tokenId" element={<OnSaleNFTDetails />} />
          <Route path="/pools" element={<YourPools />} />
          <Route path="/inventory" element={<YourNFTs />} />
          <Route path="/inventory/collection/:contractId" element={<InventoryCollectionDetails />} />
          <Route path="/inventory/:nftContract/:tokenId" element={<NFTDetails />} />
          <Route path="/create" element={<CreatePool />} />
          <Route path="/mint" element={<MintNFT />} />
          <Route path="/create-store" element={<CreateStore />} />
          <Route path="/success" element={<SuccessPage />} />
        </Routes>
        <Footer />
      </PageContainer>
    </BrowserRouter>
  )
}

export default AppRoutes
