const components = {
  // Container
  MuiContainer: {
    styleOverrides: {
      root: {
        '@media (min-width: 36rem)': {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
  },
  // AppBar
  MuiAppBar: {
    styleOverrides: {
      // Name of the slot
      root: {
        backgroundColor: 'rgba(1, 10, 25, 0.3)',
        boxShadow: 'none',
        backdropFilter: 'blur(20px)',
        justifyContent: 'center',
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        background: '#fff',
        color: '#000',
        webkitBackgroundClip: 'text',
      },
      list: {
        padding: 0,
        fontSize: '12px',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        color: '#fff',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: 600,
        borderRadius: 0,
        padding: '1rem',
        boxShadow: 'none',
        transition: 'background 0.2s ease-in-out',
        '&:after': {
          content: '""',
        },
      },
      containedPrimary: {
        background: 'linear-gradient(90deg, #f37b4a 0%, #ed3024 100%)',
        '&:hover': {
          background: 'linear-gradient(90deg, #ed3024 0%, #f37b4a 100%)',
        },
      },
      sizeMedium: {
        padding: '8px 22px',
      },
    },
  },
  MuiLoadingButton: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        '& .MuiLoadingButton-loadingIndicator': {
          color: '#ffffff',
        },
      },
      loading: {
        color: 'white',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      outlined: {
        display: 'block',
        padding: '20px',
        backgroundColor: 'rgb(19, 47, 76)',
        color: 'rgb(255, 255, 255)',
        border: '1px solid rgb(30, 73, 118)',
        borderRadius: '10px',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      flexContainer: {
        width: '100%',
        justifyContent: 'center',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      textColorPrimary: {
        color: '#ffffff',
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        minWidth: '320px',
        backgroundColor: '#001e3c',
        color: 'red',
        '@media (min-width: 375px)': {
          width: '360px',
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        margin: '0.3rem 0',
        borderColor: 'rgba(255, 255, 255, 0.5)',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none',
        color: '#1abcfe',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        color: '#fff',
        borderRadius: 0,
        '&:hover, &:focus, &.Mui-focused': {
          '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #fff',
          },
        },
        'input[type="number"]': {
          appearance: 'none',
        },
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button,': {
          appearance: 'none',
        },
        '.MuiInputBase-input': {
          padding: '0.5rem',
          '@media (min-width: 48rem)': {
            padding: '1rem',
          },
        },
      },
      notchedOutline: {
        border: '1px solid #979797',
        transition: 'all 0.5s ease',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        justifyContent: 'flex-end',
        width: '115px',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: '#000',
      },
      arrow: {
        color: '#000',
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: {
        color: '#ef4a31',
        '.MuiSlider-thumb.Mui-active': {
          boxShadow: '0px 0px 0px 8px #ef4a3221',
        },
        '.MuiSlider-thumb.Mui-focusVisible': {
          boxShadow: '0px 0px 0px 8px #ef4a3221',
        },
        '.MuiSlider-thumb:hover': {
          boxShadow: '0px 0px 0px 8px #ef4a3221',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        primary: {
          background: 'linear-gradient(90deg, #ed3024 0%, #f37b4a 100%)',
        },
      },
    },
  },
}

export default components
