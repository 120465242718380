import { useEffect, useState } from 'react'
import { IPool } from 'interfaces'
import Layout from 'components/Layout'
import { Link as RouterLink } from 'react-router-dom'
import { Typography, Link, Container } from '@mui/material'
import { CONTRACT_ID } from '../../constants'
import { HiArrowNarrowRight } from 'react-icons/hi'
import NearFTSDK from 'nearft-sdk'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CollectionCard from './CollectionCard'

function NextArrow(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36C11.18 36 4 28.82 4 20ZM0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0C8.96 0 0 8.96 0 20ZM20 18H12V22H20V28L28 20L20 12V18Z"
          fill="url(#paint0_linear_139_143)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_139_143"
            x1="0"
            y1="20.002"
            x2="39.9925"
            y2="20.002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F37B4A" />
            <stop offset="1" stop-color="#ED3024" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

function PrevArrow(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M36 20C36 11.18 28.82 4 20 4C11.18 4 4 11.18 4 20C4 28.82 11.18 36 20 36C28.82 36 36 28.82 36 20ZM40 20C40 31.04 31.04 40 20 40C8.96 40 -3.8147e-06 31.04 -3.8147e-06 20C-3.8147e-06 8.96 8.96 0 20 0C31.04 0 40 8.96 40 20ZM20 18H28V22H20V28L12 20L20 12V18Z"
          fill="url(#paint0_linear_139_146)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_139_146"
            x1="40"
            y1="20.002"
            x2="0.00747636"
            y2="20.002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F37B4A" />
            <stop offset="1" stop-color="#ED3024" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

const Home = (): JSX.Element => {
  const [isDataLoading, setDataLoading] = useState(false)
  const [pools, setPools] = useState<IPool[]>([])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      setDataLoading(true)
      const _pools = await NearFTSDK.getPools(process.env.REACT_APP_NETWORK_ID ?? 'testnet', CONTRACT_ID)
      setPools(_pools)
      setDataLoading(false)
    })()
  }, [])

  const slickSettings = {
    dots: false,
    navs: true,
    slidesToShow: pools.length > 4 ? 4 : pools.length,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <Layout>
      <Typography
        variant="h1"
        sx={{
          mb: '5rem',
          '@media (min-width: 48rem)': {
            textAlign: 'center',
            pt: '5rem',
            mb: '5rem',
          },
        }}
      >
        The Feature-rich AMM for NFT
      </Typography>
      {pools.length > 0 && (
        <Slider {...slickSettings}>
          {pools.map(pool => (
            <CollectionCard key={pool.pool_id} pool={pool} />
          ))}
        </Slider>
      )}
      <Container sx={{ textAlign: 'center', marginTop: '3rem' }}>
        <Link component={RouterLink} to="/collections" sx={{ fontSize: '1.25rem', fontWeight: 700 }}>
          <span>View all collections&nbsp;</span>
          <HiArrowNarrowRight />
        </Link>
      </Container>
    </Layout>
  )
}

export default Home
