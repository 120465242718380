import { INFTMetadata } from 'interfaces'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { PoolParams, PoolType } from './actions'

export const usePoolType = (): PoolType => {
  const poolType = useSelector((state: AppState) => state.pool.poolParams.poolType)
  return useMemo(() => poolType, [poolType])
}

export const useSelectedNFTCollection = (): string | undefined => {
  const nftCollection = useSelector((state: AppState) => state.pool.poolParams.nftCollection)
  return useMemo(() => nftCollection, [nftCollection])
}

export const useSelectedNFTs = (): INFTMetadata[] => {
  const nfts = useSelector((state: AppState) => state.pool.poolParams.selectedNFTs)
  return useMemo(() => nfts, [nfts])
}

export const usePoolParams = (): PoolParams => {
  const params = useSelector((state: AppState) => state.pool.poolParams)
  return useMemo(() => params, [params])
}
