import { createAction } from '@reduxjs/toolkit'
import { INFTMetadata } from 'interfaces'

export enum PoolType {
  UNKNOWN,
  BUY,
  SELL,
  BOTH,
}

export enum PoolCurve {
  LINEAR,
  EXPONENTIAL,
}

export interface PoolParams {
  startPrice: number
  curve: PoolCurve
  delta: number
  buyNFTAmount: number
  buyNEARAmount: number
  sellNFTAmount: number
  sellNEARAmount: number
  poolType: PoolType
  nftCollection: string
  selectedNFTs: INFTMetadata[]
  fee: number
}

export const updatePoolType = createAction<{ poolType: PoolType }>('application/updatePoolType')
export const updateNFTCollection = createAction<{ nftCollection: string }>('application/updateNFTCollection')
export const updateSelectedNFTs = createAction<{ selectedNFTs: INFTMetadata[] }>('application/updateSelectedNFTs')
export const updatePoolParams = createAction<{ poolParams: PoolParams }>('application/updatePoolParams')
export const resetPoolParams = createAction<void>('application/resetPoolParams')
