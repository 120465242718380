// export const CONTRACT_ID = 'guest-book.testnet'
export const CONTRACT_ID = 'collectibleswap.testnet'

export const TESTNET_CONFIG = {
  networkId: process.env.REACT_APP_NETWORK_ID ?? 'testnet',
  nodeUrl: 'https://near-testnet-rpc.allthatnode.com:3030',
  helperUrl: 'https://helper.testnet.near.org',
  explorerUrl: 'https://explorer.testnet.near.org',
  indexerUrl: '',
}

export const MAINNET_CONFIG = {
  networkId: process.env.REACT_APP_NETWORK_ID ?? 'mainnet',
  nodeUrl: 'https://near-testnet-rpc.allthatnode.com:3030',
  helperUrl: 'https://helper.testnet.near.org',
  explorerUrl: 'https://explorer.testnet.near.org',
  indexerUrl: '',
}

export const tags = {
  art: 'Art',
  defi: 'DeFi',
  fashion: 'Fashion',
  membership: 'Membership',
  music: 'Music',
  photography: 'Photography',
  ticket: 'Ticket',
}

export const MAIN_IMAGE = "mainImage"
export const TITLE = "title"
export const DESCRIPTION = "description"

export const VALID_STORE_NAME = /^[a-z0-9]{1,20}$/
export const EInputType = {
  NAME: 'name',
  SYMBOL: 'symbol',
}
