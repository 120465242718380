import { Box, Button, Link, Typography } from '@mui/material'
import Price from 'components/Price'
import { useCart } from 'react-use-cart'
import { Link as RouterLink } from 'react-router-dom'
import { NFTImage } from './Styled'
import DefaultNFTImage from 'assets/images/collection.png'
import { useEffect, useState } from 'react'
import { useNFTMetadata } from 'hooks'

function NFTBox({ nft, onSale }: { nft: any; onSale: boolean }): JSX.Element {
  const { addItem } = useCart()
  const metaDataCallback = useNFTMetadata(nft.metadata.reference)
  const [nftName, setNFTName] = useState('')
  const [nftImage, setNFTImage] = useState(DefaultNFTImage)
  const [nftLink, setNFTLink] = useState('')

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      if (nft.metadata.reference) {
        const _metaData = await metaDataCallback()
        setNFTName(nft.metadata.title ?? _metaData.title ?? `${nft.contractId} #${nft.tokenId}`)
        setNFTImage(nft.metadata.media ?? _metaData.media ?? DefaultNFTImage)
      } else {
        setNFTName(nft.metadata.title ?? `${nft.contractId} #${nft.tokenId}`)
        setNFTImage(nft.metadata.media ?? DefaultNFTImage)
      }
    })()

    setNFTLink(onSale ? `/collections/${nft.poolId}/${nft.tokenId}` : `/inventory/${nft.contractId}/${nft.tokenId}`)
  }, [nft])

  const onAddToCart = () => {
    addItem(nft)
  }

  return (
    <>
      <Box
        sx={{
          background: 'rgba(255, 255, 255, 0.05)',
        }}
      >
        <Link component={RouterLink} to={nftLink}>
          <NFTImage src={nftImage} alt={nftName} />
        </Link>
        <Box sx={{ padding: '1rem' }}>
          <Link component={RouterLink} to={nftLink}>
            <Typography variant="body1" sx={{ color: '#fff', wordBreak: 'break-word' }}>
              {nftName}
            </Typography>
          </Link>
          {!onSale && (
            <Link component={RouterLink} to={`/inventory/collection/${nft.contractId}`}>
              <Typography variant="body1" sx={{ fontSize: '0.75rem', color: '#ef4a31', wordBreak: 'break-word' }}>
                {nft.contractId}
              </Typography>
            </Link>
          )}
          {onSale && (
            <>
              <Price price={nft.price} fontSize="1rem" />
              <Button variant="contained" sx={{ mt: '1rem', width: '100%' }} onClick={onAddToCart}>
                Add to Cart
              </Button>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default NFTBox
