import { Typography } from '@mui/material'
import { PoolType } from 'state/pool/actions'
import { usePoolType } from 'state/pool/hooks'

function AssetsAmountsText(): JSX.Element {
  const poolType = usePoolType()

  return (
    <Typography sx={{ mt: '0.5rem', mb: '2rem', color: 'rgba(252, 253, 253, 0.8)' }}>
      {poolType === PoolType.BUY && <span>Set how many tokens you deposit into the pool.</span>}
      {poolType === PoolType.SELL && <span>Set how many NFTs you deposit into the pool.</span>}
      {poolType === PoolType.BOTH && <span>Set your NEAR and NFTs deposit amounts.</span>}
    </Typography>
  )
}

export default AssetsAmountsText
