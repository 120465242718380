import { Provider as ReduxProvider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import { WalletContextProvider } from '@mintbase-js/react'
import App from './App'
import theme from './theme/theme'
import CssBaseline from '@mui/material/CssBaseline'
import '@near-wallet-selector/modal-ui/styles.css'
import { Buffer } from 'buffer'
import store from 'state'
import ApplicationUpdater from './state/application/updater'
import Web3ReactManager from 'components/Web3ReactManager'
import 'assets/scss/style.scss'
import { MintbaseProvider } from 'config/mbjs'

window.Buffer = window.Buffer || Buffer


function Updaters() {
  return (
    <>
      <ApplicationUpdater />
    </>
  )
}

const root = createRoot(document.getElementById('root'))
root.render(
  <ThemeProvider theme={theme}>
    <ReduxProvider store={store}>
      <WalletContextProvider>
        <CssBaseline />
        <Updaters />
        <Web3ReactManager>
          <MintbaseProvider>
            <App />
          </MintbaseProvider>
        </Web3ReactManager>
      </WalletContextProvider>
    </ReduxProvider>
  </ThemeProvider>,
)
