import React from 'react'
import { Container, Paper } from '@mui/material'

interface ILayoutProps {
  children: React.ReactNode
  contentFullwidth?: React.ReactNode
}

const Layout = (props: ILayoutProps): JSX.Element => {
  const { children, contentFullwidth } = props
  return (
    <Paper
      elevation={0}
      square
      sx={{
        flexDirection: 'row',
        backgroundColor: 'transparent',
        backgroundImage: 'none',
        position: 'relative',
        overflowX: 'hidden',
      }}
    >
      <Container maxWidth="xl">
        <Paper
          elevation={0}
          square
          sx={{
            minHeight: 'calc(100vh - 10rem)',
            backgroundColor: 'transparent',
            paddingTop: '3rem',
          }}
        >
          {children}
        </Paper>
      </Container>
    </Paper>
  )
}

export default Layout
