import { Grid, Stack, Typography } from '@mui/material'
import { BsCheck } from 'react-icons/bs'
import CollectionLogo from 'components/CollectionLogo'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { updateNFTCollection } from 'state/pool/actions'

function NFTCollectionItem({
  collection,
  isSelected,
  onSelect,
}: {
  collection: string
  isSelected: boolean
  onSelect: () => void
}): JSX.Element {
  const dispatch = useDispatch<AppDispatch>()

  const onClick = () => {
    dispatch(updateNFTCollection({ nftCollection: collection }))
    onSelect()
  }

  return (
    <Grid container padding="0.5rem 1rem" alignItems="center" sx={{ cursor: 'pointer' }} onClick={onClick}>
      {isSelected ? (
        <Stack width="2rem" alignItems="center">
          <BsCheck size={20} color="#ef4a31" />
        </Stack>
      ) : (
        <Stack width="2rem">
          <CollectionLogo poolName={collection} size="2rem" borderRadius="0" />
        </Stack>
      )}
      <Typography sx={{ color: '#000', mb: 0, ml: '0.5rem' }}>{collection}</Typography>
    </Grid>
  )
}

export default NFTCollectionItem
