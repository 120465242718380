import { createReducer } from '@reduxjs/toolkit'
import {
  PoolCurve,
  PoolParams,
  PoolType,
  resetPoolParams,
  updateNFTCollection,
  updatePoolParams,
  updatePoolType,
  updateSelectedNFTs,
} from './actions'

export interface PoolState {
  readonly poolParams: PoolParams
}

const initialState: PoolState = {
  poolParams: {
    startPrice: 0,
    curve: PoolCurve.LINEAR,
    delta: 0,
    buyNFTAmount: 1,
    buyNEARAmount: 0,
    sellNFTAmount: 1,
    sellNEARAmount: 0,
    poolType: PoolType.UNKNOWN,
    nftCollection: '',
    selectedNFTs: [],
    fee: 0,
  },
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updatePoolType, (state, action) => {
      state.poolParams.poolType = action.payload.poolType
    })
    .addCase(updateNFTCollection, (state, action) => {
      state.poolParams.nftCollection = action.payload.nftCollection
    })
    .addCase(updateSelectedNFTs, (state, action) => {
      state.poolParams.selectedNFTs = action.payload.selectedNFTs
    })
    .addCase(updatePoolParams, (state, action) => {
      state.poolParams = action.payload.poolParams
    })
    .addCase(resetPoolParams, () => {
      return initialState
    }),
)
