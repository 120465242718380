import { useEffect, useState } from 'react'
import { AppDispatch } from 'state'
import { useDispatch } from 'react-redux'
import { useNFTMetadata } from 'hooks'
import { HiOutlineCheck } from 'react-icons/hi'
import { useSelectedNFTs } from 'state/pool/hooks'
import DefaultNFTImage from 'assets/images/collection.png'
import { NFTCheckbox, NFTDescription, NFTId, NFTImage, NFTItemInner, NFTItemWrapper, NFTName } from '../Styled'
import { updateSelectedNFTs } from 'state/pool/actions'

function NFTItem({ nft, isSelected }: { nft: any; isSelected: boolean }): JSX.Element {
  const dispatch = useDispatch<AppDispatch>()
  const selectedNFTs = useSelectedNFTs()
  const metaDataCallback = useNFTMetadata(nft.metadata.reference)

  const [nftName, setNFTName] = useState('')
  const [nftImage, setNFTImage] = useState(DefaultNFTImage)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      if (nft.metadata.reference) {
        const _metaData = await metaDataCallback()
        setNFTName(nft.metadata.title ?? _metaData.title ?? `${nft.contractId} #${nft.tokenId}`)
        setNFTImage(nft.metadata.media ?? _metaData.media ?? DefaultNFTImage)
      } else {
        setNFTName(nft.metadata.title ?? `${nft.contractId} #${nft.tokenId}`)
        setNFTImage(nft.metadata.media ?? DefaultNFTImage)
      }
    })()
  }, [nft])

  const onSelect = () => {
    if (selectedNFTs) {
      let list = [...selectedNFTs]
      if (!isSelected) {
        const _nft = list.find(n => n.tokenId === nft.tokenId)
        if (_nft == undefined) {
          list.push(nft)
        }
      } else {
        list = selectedNFTs.filter(n => n.tokenId !== nft.tokenId)
      }
      dispatch(updateSelectedNFTs({ selectedNFTs: list }))
    }
  }

  return (
    <NFTItemWrapper className={isSelected ? 'is-selected' : ''} onClick={onSelect}>
      <NFTItemInner>
        <NFTImage>
          <img src={nftImage} alt={nftName} />
          <NFTCheckbox className="nft-checkbox">
            <HiOutlineCheck />
          </NFTCheckbox>
        </NFTImage>
        <NFTDescription>
          <NFTName>{nftName}</NFTName>
          <NFTId>Token ID: {nft.tokenId}</NFTId>
        </NFTDescription>
      </NFTItemInner>
    </NFTItemWrapper>
  )
}

export default NFTItem
