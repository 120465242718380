import { useEffect, useState } from 'react'
import { Container, Grid, Typography } from '@mui/material'
import { useAccount } from 'state/wallet/hooks'
import { CONTRACT_ID } from '../../constants'
import NearFTSDK from 'nearft-sdk'
import Layout from 'components/Layout'
import Loader from 'components/Loader'
import NFTBox from 'components/NFTBox'
import BannerPNG from 'assets/images/CollectionDetails/banner.png'
import { BannerImg, TitleWrapper } from './Styled'

function YourNFTs(): JSX.Element {
  const account = useAccount()
  const [listNFT, setListNFT] = useState<any>([])
  const [empty, setEmpty] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const _list = await NearFTSDK.getNFTData(process.env.REACT_APP_NETWORK_ID ?? 'testnet', account, CONTRACT_ID)
      if (!_list) {
        setEmpty(true)
      } else {
        setListNFT(_list.flat(1))
        setLoading(false)
      }
    })()
  }, [account])

  if (isLoading) return <Loader />

  if (empty)
    return (
      <div className="my-20">
        <h2 className="font-poppins dark:text-white text-nft-black-1 text-center font-semibold text-2xl minlg:text-3xl">
          Not found
        </h2>
      </div>
    )

  return (
    <Layout>
      <Container sx={{ minHeight: '90vh' }}>
        <BannerImg src={BannerPNG} />
        <TitleWrapper>
          <Typography variant="h1" sx={{ zIndex: 1 }}>
            Your NFTs
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: '#fcfcfd', fontWeight: 500, fontSize: '16px', lineHeight: '24px', opacity: 0.8, mb: '2rem' }}
          >
            <span>View all of the NFTs that </span>
            {account ? <span style={{ color: '#ef4a31' }}>{account}</span> : <span>you</span>}
            <span> provides liquidity for.</span>
          </Typography>
        </TitleWrapper>

        {listNFT.length > 0 && (
          <Grid container spacing={4} sx={{ marginTop: '5rem', marginBottom: '3rem' }}>
            {listNFT.map(nft => (
              <Grid item xs={12} md={4} lg={3} key={nft.tokenId}>
                <NFTBox nft={nft} onSale={false} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Layout>
  )
}

export default YourNFTs
