import React from 'react'
import AppRoutes from './AppRoutes'
import { CartProvider } from 'react-use-cart'

const App: React.FC = () => {
  return (
    <CartProvider>
      <AppRoutes />
    </CartProvider>
  )
}

export default App
