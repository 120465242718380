import styled from 'styled-components/macro'

export const BannerImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.3;
  z-index: 0;

  @media (min-width: 120rem) {
    width: 100vw;
  }
`

export const TitleWrapper = styled.div`
  position: relative;
  text-align: center;

  @media (min-width: 36rem) {
    margin-top: 3rem;
  }

  @media (min-width: 160rem) {
    margin-top: 6rem;
  }
`
