import { Box, Grid, Slider, Typography, useMediaQuery, useTheme } from '@mui/material'
import NumberInput from 'components/NumberInput'
import { floor } from 'mathjs'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { PoolCurve, updatePoolParams } from 'state/pool/actions'
import { usePoolParams } from 'state/pool/hooks'
import NFTCollectionSelect from './NFTCollectionSelect'

// (1-1*10%)-(0.9*1%)
// P=(p - p*delta)
// P2 = P - P*fee

// protocol fee: 0.005
// ----fee = 1%, delta = 5, 0.05----
// 100 - 98.029 = 1.971
// 95 - 93.079 = 1.921
// 90 - 88.129 = 1.871
// 85 - 83.178 = 1.822
// 80 - 78.228 = 1.772
// 75 - 73.277 = 1.723

// ----fee = 2%, delta = 5, 0.098----
// 100 - 96.117 = 3.883
// 95 - 91.215 = 3.785
// 90 - 86.313 = 3.687
// 85 - 81.411 = 3.589
// 80 - 76.509 = 3.491
// 75 - 71.607 = 3.393

// ----fee = 3%, delta = 5, 0.146----
// 100 - 94.259 = 5.741
// 95 - 89.405 = 5.595
// 90 - 84.551 = 5.449
// 85 - 79.696 = 5.304
// 80 - 74.842 = 5.158
// 75 - 69.988 = 5.012

// ----fee = 5%, delta = 5, 0.238----
// 100 - 90.703 = 9.297
// 95 - 85.941 = 9.059
// 90 - 81.179 = 8.821
// 85 - 76.417 = 8.583
// 80 - 71.655 = 8.345
// 75 - 66.893 = 8.107

// ----fee = 7%, delta = 5, 0.327----
// 100 - 87.344 = 12.656
// 95 - 82.671 = 12.329
// 90 - 77.998 = 12.002
// 85 - 73.325 = 11.675
// 80 - 68.652 = 11.348
// 75 - 63.979 = 11.021

// ----fee = 8%, delta = 5, 0.371----
// 100 - 85.733 = 14.267
// 95 - 81.104 = 13.896
// 90 - 76.475 = 13.525
// 85 - 71.845 = 13.155
// 80 - 67.215 = 12.785
// 75 - 62.586 = 12.414

// 425-415.891=9.109
// 425-407.055=17.945
// 425-398.482=26.518
// 425-382.086=42.914
// 425-345.041=79.959

// D = delta/100+1
// p1 = p / D
// p2 = p1 / D
// p3 = p2 / D
// 0.909 + 0.826 + 0.751 = 2.486

// p / D + p1 / D + p2 / D
// p / D + p/D/D + p/D/D/D
// p/D + p/D^2 + p/D^3
// p(1/D + 1/D^2 + 1/D^3)
// p(A + A^2 + A^3)
// p(A^4 - A)/(A - 1)
// p(1/D^4 - 1/D)/(1/D - 1)

function AssetsFormBoth(): JSX.Element {
  const poolParams = usePoolParams()
  const dispatch = useDispatch<AppDispatch>()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [tmpBuyNEAR, setTmpBuyNEAR] = useState(0)
  const [totalBuyNEAR, setTotalBuyNEAR] = useState(0)
  const [totalSellNEAR, setTotalSellNEAR] = useState(0)
  const [maxBuyNFTAmount, setMaxBuyNFTAmount] = useState(0)
  const [buySlideNFTAmount, setBuySlideNFTAmount] = useState(0)
  const [sellSlideNFTAmount, setSellSlideNFTAmount] = useState(0)

  const { curve, delta, startPrice, buyNFTAmount, sellNFTAmount } = poolParams

  const calculateBuyNEAR = (_amount: number): number => {
    if (curve === PoolCurve.EXPONENTIAL) {
      const _delta = 1 + delta / 100
      return (startPrice * (1 / _delta ** (_amount + 1) - 1 / _delta)) / (1 / _delta - 1)
    }
    return startPrice * _amount - ((_amount * (_amount + 1)) / 2) * delta
  }

  useEffect(() => {
    let _nftAmount = buyNFTAmount
    const _maxNFTAmount = delta > 0 ? floor(startPrice / delta) : startPrice * _nftAmount
    setMaxBuyNFTAmount(_maxNFTAmount)

    if (curve === PoolCurve.LINEAR && (_nftAmount < 0 || _nftAmount > _maxNFTAmount)) {
      _nftAmount = _maxNFTAmount
    }

    const _buyNEARAmount = calculateBuyNEAR(_nftAmount)

    setTotalBuyNEAR(_buyNEARAmount)
    setTmpBuyNEAR(_buyNEARAmount)

    dispatch(
      updatePoolParams({
        poolParams: {
          ...poolParams,
          buyNEARAmount: _buyNEARAmount,
        },
      }),
    )
  }, [curve, delta, startPrice, buyNFTAmount])

  const onBuyNFTAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _amount = Number(event.target.value)

    if (_amount < buySlideNFTAmount) {
      setBuySlideNFTAmount(_amount)
      setTotalBuyNEAR(calculateBuyNEAR(_amount))
    }

    dispatch(
      updatePoolParams({
        poolParams: {
          ...poolParams,
          buyNFTAmount: _amount,
        },
      }),
    )
  }

  const onBuySlideChange = (event: Event, value: number | number[]) => {
    const _nftAmounts = value as number
    setBuySlideNFTAmount(_nftAmounts)
    setTotalBuyNEAR(calculateBuyNEAR(_nftAmounts))
  }

  const calculateSellNEAR = (_amount: number): number => {
    if (curve === PoolCurve.EXPONENTIAL) {
      const _delta = 1 + delta / 100
      return delta > 0 ? startPrice * (1 + (_delta - _delta ** _amount) / (1 - _delta)) : startPrice * _amount
    }
    return startPrice * _amount + (((_amount - 1) * _amount) / 2) * delta
  }

  useEffect(() => {
    dispatch(
      updatePoolParams({
        poolParams: {
          ...poolParams,
          sellNEARAmount: calculateSellNEAR(sellNFTAmount),
        },
      }),
    )
    setTotalSellNEAR(calculateSellNEAR(sellNFTAmount))
  }, [curve, delta, startPrice, sellNFTAmount])

  const onSellNFTAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _amount = Number(event.target.value)

    if (_amount < buySlideNFTAmount) {
      setSellSlideNFTAmount(_amount)
      setTotalSellNEAR(calculateSellNEAR(_amount))
    } else {
      setSellSlideNFTAmount(buySlideNFTAmount)
      setTotalSellNEAR(calculateSellNEAR(buySlideNFTAmount))
    }

    dispatch(
      updatePoolParams({
        poolParams: {
          ...poolParams,
          sellNFTAmount: _amount,
        },
      }),
    )
  }

  const onSellSlideChange = (event: Event, value: number | number[]) => {
    const _nftAmounts = value as number
    setSellSlideNFTAmount(_nftAmounts)
    setTotalSellNEAR(calculateSellNEAR(_nftAmounts))
  }

  return (
    <>
      <Box
        sx={{
          mt: '2rem',
        }}
      >
        {curve === PoolCurve.EXPONENTIAL ? (
          <NumberInput
            label="Buy up to"
            description=""
            defaultValue={0}
            showFloorPrice={true}
            validWhen={buyNFTAmount >= 0}
            invalidMessage="Must be greater than 0"
            isCollectionInput={true}
            adorment={!isMobile && <NFTCollectionSelect />}
            onChange={onBuyNFTAmountChange}
          />
        ) : (
          <NumberInput
            label="Buy up to"
            description=""
            defaultValue={0}
            showFloorPrice={true}
            validWhen={buyNFTAmount >= 1 && maxBuyNFTAmount >= 0 && buyNFTAmount <= maxBuyNFTAmount}
            invalidMessage={
              maxBuyNFTAmount < 1
                ? ''
                : buyNFTAmount >= 0
                ? `You can only buy up to ${maxBuyNFTAmount} NFTs at the current price curve. Either increase the start price or
              decrease the delta to allow for more buys.`
                : 'Must be greater than 0'
            }
            isCollectionInput={true}
            adorment={!isMobile && <NFTCollectionSelect />}
            onChange={onBuyNFTAmountChange}
          />
        )}
        {isMobile && <NFTCollectionSelect />}
        <Typography textAlign="center" sx={{ mt: { xs: '1rem', lg: '-0.5rem' }, mb: '1.5rem', fontWeight: 500 }}>
          Deposit NEAR to buy up to {buyNFTAmount} {buyNFTAmount > 1 ? 'NFTs' : 'NFT'}
        </Typography>
      </Box>

      <Typography
        variant="h4"
        textAlign="center"
        sx={{ mt: { xs: '1rem', lg: '-0.5rem' }, mb: '1rem', fontWeight: 500 }}
      >
        and
      </Typography>

      <Box>
        <NumberInput
          label="Sell up to"
          description=""
          defaultValue={0}
          showFloorPrice={true}
          validWhen={buyNFTAmount >= 0}
          invalidMessage={'Must be greater than 0'}
          isCollectionInput={true}
          adorment={!isMobile && <NFTCollectionSelect />}
          onChange={onSellNFTAmountChange}
        />
        {isMobile && <NFTCollectionSelect />}
        <Typography textAlign="center" sx={{ mt: { xs: '1rem', lg: '-0.5rem' }, mb: '1.5rem' }}>
          Deposit {sellNFTAmount} {sellNFTAmount > 1 ? 'NFTs' : 'NFT'} to sell for NEAR
        </Typography>
      </Box>

      <hr />

      <Box sx={{ textAlign: 'center', py: '1rem' }}>
        <Typography>You will deposit:</Typography>
        <Typography variant="h4">
          <strong>{Number.parseFloat(tmpBuyNEAR.toFixed(3))}</strong>
          <span> NEAR</span>
          <span style={{ fontSize: '1rem' }}>&nbsp;&nbsp;&nbsp;and&nbsp;&nbsp;&nbsp;</span>
          <strong>{sellNFTAmount}</strong>
          <span> {sellNFTAmount > 1 ? 'NFTs' : 'NFT'}</span>
        </Typography>
      </Box>

      <hr />

      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography marginTop="1.5rem">
              Buying {buySlideNFTAmount} {buySlideNFTAmount > 1 ? 'NFTs' : 'NFT'}...
            </Typography>
            <Slider
              disabled={buyNFTAmount === 0}
              defaultValue={0}
              step={1}
              min={0}
              max={buyNFTAmount}
              onChange={onBuySlideChange}
              sx={{ width: '60%', marginX: 'auto', display: 'block' }}
            />
            <Typography marginTop="0.5rem">
              will cost you <strong>{Number.parseFloat(totalBuyNEAR.toFixed(3))}</strong> NEAR
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography marginTop="1.5rem">
              Selling {sellSlideNFTAmount} {sellSlideNFTAmount > 1 ? 'NFTs' : 'NFT'}...
            </Typography>
            <Slider
              disabled={sellNFTAmount === 0}
              defaultValue={0}
              step={1}
              min={0}
              max={sellNFTAmount}
              onChange={onSellSlideChange}
              sx={{ width: '60%', marginX: 'auto', display: 'block' }}
            />
            <Typography marginTop="0.5rem">
              will earn you <strong>{Number.parseFloat(totalSellNEAR.toFixed(3))}</strong> NEAR
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default AssetsFormBoth
