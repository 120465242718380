import NavItem from './NavItem.type'

const NAV_ITEMS: NavItem[] = [
  {
    label: 'Collections',
    slug: 'collections',
    to: '/collections',
  },
  {
    label: 'Your pools',
    slug: 'pools',
    to: '/pools',
  },
  {
    label: 'Your NFTs',
    slug: 'inventory',
    to: '/inventory',
  },
  {
    label: 'Mint NFT',
    slug: 'mint',
    to: '/mint',
  },
]

export default NAV_ITEMS
