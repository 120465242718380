import styled from 'styled-components/macro'
import { ToggleProps } from 'types/toggles.type'
// @ts-ignore
import CheckIcon from '@mui/icons-material/Check'

interface ToggleChipProps extends ToggleProps {
  isChecked: boolean
  disabled: boolean
  handleClick: () => void
}

const ToggleChipWrapper = styled.div<{ isChecked: boolean; disabled: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 3px;
  margin-right: 8px;
  padding: 5px 18px;
  background: ${(props) => (props.isChecked ? 'linear-gradient(90deg, #f37b4a 0%, #ed3024 100%)' : 'rgba(235, 237, 251, 1)')};
  color: ${(props) => props.isChecked && 'rgba(255, 255, 255, 1)'};
  transition: all 0.5s ease-in 0s;
  border-radius: 99px;
  cursor: pointer;
`

const ToggleChipLabel = styled.span`
  display: inline-block;
`

export const ToggleChip = (props: ToggleChipProps) => {
  const { label, isChecked, disabled, handleClick } = props
  return (
    <>
      <ToggleChipWrapper
        isChecked={isChecked}
        disabled={disabled}
        onClick={() => {
          if (disabled) return
          handleClick()
        }}
      >
        {isChecked && <CheckIcon fontSize="small" />}
        <ToggleChipLabel>{label}</ToggleChipLabel>
      </ToggleChipWrapper>
    </>
  )
}
