import { Link } from 'react-router-dom'
import { DesktopLogo, HeaderWrapper, MobileLogo } from './Styled'
import DesktopLogoSVG from 'assets/images/logo.svg'
import MobileLogoPNG from 'assets/images/logo-mobile.png'
import { useAccount } from 'state/wallet/hooks'
import AccountButton from './AccountButton'
import { Box, Container, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import Cart from './Cart'
import MenuMobile from './MenuMobile'
import DesktopNav from './DesktopNav'

const Header = (): JSX.Element => {
  const account = useAccount()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <HeaderWrapper position="static" elevation={0}>
      <Container maxWidth={false}>
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Link to="/">
            <DesktopLogo src={DesktopLogoSVG} alt="Collectible Swap" />
            <MobileLogo src={MobileLogoPNG} alt="Collectible Swap" />
          </Link>

          {!isMobile && <DesktopNav />}

          <Box sx={{ flexGrow: 0 }}>
            <AccountButton />
            {account && <Cart />}

            {isMobile && <MenuMobile />}
          </Box>
        </Toolbar>
      </Container>
    </HeaderWrapper>
  )
}
export default Header
