import { useEffect, useState } from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { Button, Container, Grid, Link, Stack, Tooltip, Typography } from '@mui/material'
import NearFTSDK from 'nearft-sdk'
import Layout from 'components/Layout'
import Loader from 'components/Loader'
import { CONTRACT_ID } from '../../constants'
import { useNFTMetadata } from 'hooks'
import { BiLinkExternal } from 'react-icons/bi'
import { InfoTitle, NFTImage } from './Styled'
import DefaultNFTImage from 'assets/images/collection.png'
import Price from 'components/Price'
import CollectionLogo from 'components/CollectionLogo'
import { getExplorerLink } from 'utils/utils'

function OnSaleNFTDetails(): JSX.Element {
  const { poolId, tokenId } = useParams()
  const [nftInfo, setNFTInfo] = useState<any>()
  const [tokenMetadata, setTokenMetadata] = useState<any>()
  const metaDataCallback = useNFTMetadata(tokenMetadata?.reference)

  const [nftName, setNFTName] = useState('')
  const [nftImage, setNFTImage] = useState(DefaultNFTImage)
  const [nftDescription, setNFTDescription] = useState('')
  const [nftPrice, setNFTPrice] = useState()
  const [poolImage, setPoolImage] = useState('')

  const [empty, setEmpty] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const getMetadata = async () => {
      const _pool = await NearFTSDK.getPoolInfo(
        process.env.REACT_APP_NETWORK_ID ?? 'testnet',
        CONTRACT_ID,
        Number(poolId),
      )

      if (!_pool) {
        setEmpty(true)
      } else {
        const _tokenPool = _pool.poolTokenMetadata[tokenId]
        const _tokenMetadata = _tokenPool.metadata
        setTokenMetadata(_tokenMetadata)
        setNFTInfo(_tokenPool)
        setNFTPrice(_pool.spot_price)
        setPoolImage(_pool.nft_token)
        setLoading(false)
      }
    }
    getMetadata()
  }, [poolId, tokenId])

  useEffect(() => {
    const getNFTDetails = async () => {
      if (tokenMetadata) {
        if (tokenMetadata.reference && nftInfo) {
          const _metaData = await metaDataCallback()
          setNFTName(_metaData.title ?? _metaData.title ?? `${nftInfo?.contractId} #${nftInfo?.tokenId}`)
          setNFTImage(_metaData.media ?? _metaData.media ?? DefaultNFTImage)
          setNFTDescription(_metaData.description && _metaData.description)
        } else {
          setNFTName(tokenMetadata.title ?? `${tokenMetadata.contractId} #${nftInfo?.tokenId}`)
          setNFTImage(tokenMetadata.media ?? DefaultNFTImage)
        }
      }
    }
    getNFTDetails()
  }, [tokenMetadata, nftInfo])

  if (isLoading) return <Loader />

  if (empty) {
    return (
      <div className="my-20">
        <h2>Not found</h2>
      </div>
    )
  }

  return (
    <Layout>
      <Container>
        <Grid container spacing={3}>
          <Grid item sm={5}>
            <NFTImage src={nftImage} alt={nftName} />
            <Stack
              sx={{
                marginTop: '2rem',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                padding: '1rem',
              }}
            >
              <InfoTitle>Details</InfoTitle>
              <Grid
                container
                direction={{ xs: 'column', lg: 'row' }}
                justifyContent="space-between"
                sx={{ mb: '0.25rem' }}
              >
                <Typography variant="subtitle1" sx={{ color: '#fcfcfd', opacity: 0.5 }}>
                  Contract Address:
                </Typography>
                <Tooltip title="View on explorer" arrow placement="top">
                  <Link
                    href={`${getExplorerLink(nftInfo.contractId, 'address')}`}
                    target="_blank"
                    variant="subtitle1"
                    sx={{ wordBreak: 'break-word' }}
                  >
                    {nftInfo.contractId}&nbsp;
                    <BiLinkExternal size={14} />
                  </Link>
                </Tooltip>
              </Grid>
              <Grid container direction={{ xs: 'column', lg: 'row' }} justifyContent="space-between" sx={{ mb: 0 }}>
                <Typography variant="subtitle1" sx={{ mb: '0', color: '#fcfcfd', opacity: 0.5 }}>
                  Token Id:
                </Typography>
                <Typography variant="subtitle1">{nftInfo.tokenId}</Typography>
              </Grid>
            </Stack>
          </Grid>

          <Grid item sm={7}>
            <Stack>
              <Link
                variant="body2"
                component={RouterLink}
                to={`/collections/${poolId}`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'fit-content',
                  color: '#fff',
                  wordBreak: 'break-word',
                }}
              >
                <CollectionLogo poolName={poolImage} size="1.25rem" borderRadius="100%" />
                &nbsp;
                {nftInfo.contractId}
              </Link>
              <Typography variant="h2" sx={{ fontSize: '2rem', lineHeight: 2, marginTop: '0.5rem' }}>
                {nftName}
              </Typography>
              <Stack
                sx={{
                  marginTop: '2rem',
                  background: 'rgba(255, 255, 255, 0.05)',
                  padding: '1rem',
                }}
              >
                <Grid container sx={{ alignItems: 'center' }}>
                  <div>
                    <Typography variant="subtitle1" sx={{ color: '#fcfcfd', opacity: 0.5 }}>
                      Price
                    </Typography>
                    <Price price={nftPrice} alignment="left" fontSize="14px" />
                  </div>
                  <Button
                    variant="contained"
                    sx={{
                      ml: 'auto',
                      mr: 0,
                      mt: '1rem',
                      width: '100%',
                      '@media (min-width: 992px)': {
                        width: '50%',
                        mt: 0,
                      },
                    }}
                  >
                    Buy NFT
                  </Button>
                </Grid>
                <hr />
                <div>
                  <Typography variant="subtitle1" sx={{ color: '#fcfcfd', opacity: 0.5 }}>
                    Owner
                  </Typography>
                  <Tooltip title="View on explorer" arrow placement="top">
                    <Link href={`${getExplorerLink(nftInfo.ownerId, 'address')}`} target="_blank">
                      {nftInfo.ownerId}&nbsp;
                      <BiLinkExternal size={14} />
                    </Link>
                  </Tooltip>
                </div>
              </Stack>
              <Stack
                sx={{
                  marginTop: '2rem',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  padding: '1rem',
                }}
              >
                <InfoTitle>Properties</InfoTitle>
                <Typography variant="body1" sx={{ color: '#fcfcfd', opacity: 0.5 }}>
                  {nftDescription}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default OnSaleNFTDetails
