import axios from 'axios'
import { useCallback } from 'react'

export const useNFTMetadata = (referenceUrl: string): (() => Promise<any>) => {
  const callback = useCallback(async (): Promise<any> => {
    const response = await axios.get(referenceUrl)

    if (response.status === 200) {
      return response.data
    }
    return {}
  }, [referenceUrl])

  return callback
}
