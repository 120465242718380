import { Container } from '@mui/system'
import Layout from 'components/Layout'
import { BannerImg, CollectionAddress, CollectionName, DetailWrapper, LogoWrapper } from './Styled'
import BannerPNG from 'assets/images/CollectionDetails/banner.png'
import { Grid, Link, Tooltip } from '@mui/material'
import CollectionLogo from 'components/CollectionLogo'
import { useParams } from 'react-router-dom'
import { BiLinkExternal } from 'react-icons/bi'
import { RiFileCopyLine } from 'react-icons/ri'
import { getExplorerLink } from 'utils/utils'
import { useCopyClipboard } from 'hooks'
import { useEffect, useState } from 'react'
import { CONTRACT_ID } from '../../constants'
import NearFTSDK from 'nearft-sdk'
import { useAccount } from 'state/wallet/hooks'
import Loader from 'components/Loader'
import NFTBox from 'components/NFTBox'

function InventoryCollectionDetails(): JSX.Element {
  const { contractId } = useParams()
  const account = useAccount()
  const [isCopied, setCopied] = useCopyClipboard()

  const [listNFT, setListNFT] = useState<any>([])
  const [isLoading, setLoading] = useState(true)

  const url = `https://firebasestorage.googleapis.com/v0/b/omni-live.appspot.com/o/store%2F${contractId}-header?alt=media`

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const _list = await NearFTSDK.getNFTData(process.env.REACT_APP_NETWORK_ID ?? 'testnet', account, CONTRACT_ID)
      setListNFT(_list.flat(1).filter(n => n.contractId === contractId))
      setLoading(false)
    })()
  }, [account])

  if (isLoading) return <Loader />

  if (listNFT.length === 0)
    return (
      <div className="my-20">
        <h2 className="font-poppins dark:text-white text-nft-black-1 text-center font-semibold text-2xl minlg:text-3xl">
          Not found
        </h2>
      </div>
    )

  return (
    <Layout>
      <Container sx={{ minHeight: '90vh' }}>
        <DetailWrapper>
          <BannerImg
            src={url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = BannerPNG
            }}
            alt={contractId}
          />
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <LogoWrapper>
              <CollectionLogo poolName={contractId} size="9.375rem" borderRadius="0" />
            </LogoWrapper>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '2.5rem' }}>
            <CollectionName variant="h1">{contractId}</CollectionName>
            <Grid container gap={1} sx={{ alignItems: 'center', width: 'fit-content', marginX: 'auto' }}>
              <Tooltip title={isCopied ? 'Copied' : 'Copy'} arrow placement="top">
                <CollectionAddress onClick={() => setCopied(contractId)}>
                  <span>{contractId}&nbsp;</span>
                  <RiFileCopyLine fill="#fff" size={26} />
                </CollectionAddress>
              </Tooltip>
              <Tooltip title="View on explorer" arrow placement="top">
                <Link
                  href={`${getExplorerLink(contractId, 'contract')}`}
                  target="_blank"
                  sx={{ color: '#fff', '&:hover': { color: '#ef4a31' } }}
                >
                  <BiLinkExternal size={26} />
                </Link>
              </Tooltip>
            </Grid>
          </Grid>
        </DetailWrapper>

        {listNFT.length > 0 && (
          <Grid container spacing={4} sx={{ marginTop: '5rem', marginBottom: '3rem' }}>
            {listNFT.map(nft => (
              <Grid item xs={12} md={4} lg={3} key={nft.tokenId}>
                <NFTBox nft={nft} onSale={false} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Layout>
  )
}

export default InventoryCollectionDetails
