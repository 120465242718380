import { Box, Slider, Typography, useMediaQuery, useTheme } from '@mui/material'
import NumberInput from 'components/NumberInput'
import { floor } from 'mathjs'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { PoolCurve, updatePoolParams } from 'state/pool/actions'
import { usePoolParams } from 'state/pool/hooks'
import NFTCollectionSelect from './NFTCollectionSelect'

function AssetsFormBuy(): JSX.Element {
  const poolParams = usePoolParams()
  const dispatch = useDispatch<AppDispatch>()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [totalNEAR, setTotalNEAR] = useState(0)
  const [nftAmount, setNFTAmount] = useState(0)
  const [maxNFTAmount, setMaxNFTAmount] = useState(0)

  const { curve, delta, startPrice, buyNFTAmount, buyNEARAmount } = poolParams

  const calculateNEAR = (_amount: number): number => {
    if (curve === PoolCurve.EXPONENTIAL) {
      const _delta = 1 - delta / 100
      return delta > 0 ? startPrice * (1 + (_delta - _delta ** _amount) / (1 - _delta)) : startPrice * _amount
    }
    return startPrice * _amount - (((_amount - 1) * _amount) / 2) * delta
  }

  useEffect(() => {
    let _nftAmount = buyNFTAmount
    const _maxNFTAmount = delta > 0 ? floor(startPrice / delta) : startPrice * _nftAmount
    setMaxNFTAmount(_maxNFTAmount)

    if (curve === PoolCurve.LINEAR && (_nftAmount < 0 || _nftAmount > _maxNFTAmount)) {
      _nftAmount = _maxNFTAmount
    }

    const _buyNEARAmount = calculateNEAR(_nftAmount)

    setTotalNEAR(_buyNEARAmount)

    dispatch(
      updatePoolParams({
        poolParams: {
          ...poolParams,
          buyNEARAmount: _buyNEARAmount,
        },
      }),
    )
  }, [curve, delta, startPrice, buyNFTAmount])

  const onBuyNFTAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _amount = Number(event.target.value)

    if (_amount < nftAmount) {
      setNFTAmount(_amount)
      setTotalNEAR(calculateNEAR(_amount))
    }

    dispatch(
      updatePoolParams({
        poolParams: {
          ...poolParams,
          buyNFTAmount: _amount,
        },
      }),
    )
  }

  const onSlideChange = (event: Event, value: number | number[]) => {
    const _nftAmount = value as number
    setNFTAmount(_nftAmount)
    setTotalNEAR(calculateNEAR(_nftAmount))
  }

  return (
    <>
      <Box
        sx={{
          mt: '2rem',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 500 }}>
          If you want to buy
        </Typography>
        {curve === PoolCurve.EXPONENTIAL ? (
          <NumberInput
            label=""
            description=""
            defaultValue={0}
            showFloorPrice={true}
            validWhen={buyNFTAmount >= 0}
            invalidMessage="Must be greater than 0"
            isCollectionInput={true}
            adorment={!isMobile && <NFTCollectionSelect />}
            onChange={onBuyNFTAmountChange}
          />
        ) : (
          <NumberInput
            label=""
            description=""
            defaultValue={0}
            showFloorPrice={true}
            validWhen={buyNFTAmount >= 1 && maxNFTAmount >= 0 && buyNFTAmount <= maxNFTAmount}
            isCollectionInput={true}
            invalidMessage={
              maxNFTAmount < 1
                ? ''
                : buyNFTAmount >= 0
                ? `You can only buy up to ${maxNFTAmount} NFTs at the current price curve. Either increase the start price or
            decrease the delta to allow for more buys.`
                : 'Must be greater than 0'
            }
            adorment={!isMobile && <NFTCollectionSelect />}
            onChange={onBuyNFTAmountChange}
          />
        )}
        {isMobile && <NFTCollectionSelect />}
      </Box>
      <Box>
        <Typography variant="h4" textAlign="center" sx={{ mt: { xs: '1rem', lg: 0 }, mb: '1.5rem', fontWeight: 500 }}>
          you will need to deposit <strong>{Number.parseFloat(buyNEARAmount.toFixed(3))}</strong> NEAR total
        </Typography>
      </Box>
      <hr />
      <Box sx={{ textAlign: 'center' }}>
        <Typography marginTop="1.5rem">
          Buying {nftAmount} {nftAmount > 1 ? 'NFTs' : 'NFT'}...
        </Typography>
        <Slider
          disabled={buyNFTAmount === 0}
          defaultValue={0}
          step={1}
          min={0}
          max={buyNFTAmount}
          onChange={onSlideChange}
          sx={{ width: '60%', marginX: 'auto', display: 'block' }}
        />
        <Typography marginTop="0.5rem">
          will cost you <strong>{Number.parseFloat(totalNEAR.toFixed(3))}</strong> NEAR
        </Typography>
      </Box>
    </>
  )
}

export default AssetsFormBuy
