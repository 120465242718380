import { Grid, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { StyledBox, Description, Title } from './Styled'
import NearLogo from 'assets/images/near-logo.png'
import NFTLogo from 'assets/images/nft-logo.png'
import BothLogo from 'assets/images/both.png'
import TradingFees from 'assets/images/trading-fees.png'
import { FiChevronsRight } from 'react-icons/fi'
import { PoolType, updatePoolType } from 'state/pool/actions'

function Step1({ onNext }: { onNext: () => void }): JSX.Element {
  const dispatch = useDispatch<AppDispatch>()
  const onSelectPoolType = (poolType: PoolType) => {
    onNext()
    dispatch(updatePoolType({ poolType }))
  }

  return (
    <Grid container justifyContent="center" spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mt: 2, mb: 0 }}>
          Step 1/3: Selecting Pool Type
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <StyledBox onClick={() => onSelectPoolType(PoolType.BUY)}>
          <div>
            <Title>Buy NFTs with tokens</Title>
            <Grid container justifyContent="center" alignItems="center" gap={2} sx={{ mb: '1.5rem' }}>
              <img src={NearLogo} alt="" />
              <FiChevronsRight size={32} color="#fff" />
              <img src={NFTLogo} alt="" />
            </Grid>
            <Description>
              You will deposit tokens and receive NFTs as people swap their NFTs for your deposited tokens.
            </Description>
          </div>
        </StyledBox>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <StyledBox onClick={() => onSelectPoolType(PoolType.SELL)}>
          <Title>Sell NFTs for tokens</Title>
          <Grid container justifyContent="center" alignItems="center" gap={2} sx={{ mb: '1.5rem' }}>
            <img src={NFTLogo} alt="" />
            <FiChevronsRight size={32} color="#fff" />
            <img src={NearLogo} alt="" />
          </Grid>
          <Description>
            You will deposit NFTs and receive tokens as people swap their tokens for your deposited NFTs.
          </Description>
        </StyledBox>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <StyledBox onClick={() => onSelectPoolType(PoolType.BOTH)}>
          <Title>Do both and earn trading fees</Title>
          <Grid container justifyContent="center" alignItems="center" gap={2} sx={{ mb: '1.5rem' }}>
            <img src={BothLogo} alt="" />
            <FiChevronsRight size={32} color="#fff" />
            <img src={TradingFees} alt="" />
          </Grid>
          <Description>
            You will deposit both NFTs and tokens and earn trading fees as people buy or sell NFTs using your pool.
          </Description>
        </StyledBox>
      </Grid>
    </Grid>
  )
}

export default Step1
