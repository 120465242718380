import { IPool } from 'interfaces'
import React, { useEffect, useState } from 'react'
import DefaulTNFTCollectionLogo from 'assets/images/collection.png'
import styled from 'styled-components/macro'

const CollectionImage = styled.img.attrs(props => ({
  // @ts-ignore
  size: props.size || '6.25rem',
  // @ts-ignore
  borderRadius: props.borderRadius || '100%',
}))`
  margin-left: auto;
  margin-right: auto;
  height: ${props => props.size};
  width: ${props => props.size};
  object-fit: cover;
  border-radius: ${props => props.borderRadius};
`

interface CollectionLogoProps {
  poolName: string
  size?: string
  borderRadius?: string
}

function CollectionLogo(props: CollectionLogoProps): JSX.Element {
  const { poolName, size, borderRadius } = props
  const url = `https://firebasestorage.googleapis.com/v0/b/omni-live.appspot.com/o/store%2F${poolName}-profile?alt=media`

  return (
    <CollectionImage
      src={url}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src = DefaulTNFTCollectionLogo
      }}
      alt={poolName}
      size={size ?? '6.25rem'}
      borderRadius={borderRadius ?? '100%'}
      // @ts-ignore
      className={props.className}
    />
  )
}

export default CollectionLogo
