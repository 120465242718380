import { useEffect, useState } from 'react'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import Price from 'components/Price'
import { MdClose } from 'react-icons/md'
import { Item, useCart } from 'react-use-cart'
import DefaultNFTImage from 'assets/images/collection.png'
import { useNFTMetadata } from 'hooks'
import { CartItemImage } from './Styled'

function CartItem({ item }: { item: Item }): JSX.Element {
  const { removeItem } = useCart()
  const [nftName, setNFTName] = useState('')
  const [nftImage, setNFTImage] = useState(DefaultNFTImage)
  const metaDataCallback = useNFTMetadata(item.metadata.reference)

  useEffect(() => {
    const getNFTDetails = async () => {
      if (item.metadata.reference) {
        const _metaData = await metaDataCallback()
        setNFTName(_metaData.title ?? _metaData.title ?? `${item.contractId} #${item.tokenId}`)
        setNFTImage(_metaData.media ?? _metaData.media ?? DefaultNFTImage)
      }
    }
    getNFTDetails()
  }, [item])

  return (
    <Box key={item.id} sx={{ pb: '16px', mb: '16px', borderBottom: '1px dashed #fff' }}>
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <CartItemImage src={nftImage} alt={nftName} style={{ maxWidth: '80px' }} />
          <Box sx={{ ml: '16px' }}>
            <Typography variant="body2" fontSize="16px">
              {nftName}
            </Typography>
            <Typography>
              <span>Price:</span>
              <Price price={item.price} fontSize="1rem" />
            </Typography>
          </Box>
        </Stack>
        <Tooltip title={`Remove #${item.tokenId}`}>
          <IconButton
            aria-label={`Remove #${item.tokenId}`}
            size="small"
            onClick={() => removeItem(item.id)}
            sx={{ color: '#ffffff' }}
          >
            <MdClose color="inherit" fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  )
}

export default CartItem
