import { Grid, Typography } from '@mui/material'
import NearTokenPNG from 'assets/images/near-token.png'
import styled from 'styled-components/macro'
import { toRoundedReadableNumber } from 'utils/number'

const NearLogo = styled.img`
  display: inline-block !important;
  vertical-align: middle;
  width: 1.25rem;
  height: 1.25rem;
`

function Price({
  price,
  precision,
  alignment,
  noFormat,
  fontSize,
}: {
  price: string | number
  precision?: number
  alignment?: 'left' | 'right' | 'center'
  noFormat?: boolean
  fontSize?: string
}): JSX.Element {
  const formatedPrice = toRoundedReadableNumber({
    decimals: 24,
    number: price.toString(),
    precision: precision ?? 3,
  })
  return (
    <Grid
      container
      justifyContent={alignment == 'right' ? 'flex-end' : alignment == 'center' ? 'center' : 'flex-start'}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
        <NearLogo src={NearTokenPNG} alt="near-logo" />
      </Grid>
      <Grid item>
        <Typography variant="body2" sx={{ fontSize: fontSize ?? '1.25rem', lineHeight: 2, marginLeft: '0.5rem' }}>
          {noFormat === true ? price : formatedPrice}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Price
