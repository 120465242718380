import React, { forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Chip } from '@mui/material'

const InputTagsLabel = styled.label`
  display: block;
  color: #ffffff;
  margin-bottom: 5px;
`

const InputTagsWrap = styled.div`
  display: flex;
  align-items: center;
  max-height: 55px;
  padding: 15px;
  overflow-x: scroll;
  overflow-y: hidden;
  border: 1px solid #979797;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }
`

const Input = styled.input`
  background: none;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  outline: none;
  border: none;
  ::placeholder {
    color: #ffffff;
  }
`

interface InputTagsProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  maxTags: number
  onTagsChange: (tags: string[]) => void
  onMaxTags: () => void
}

export const InputTags = forwardRef<HTMLInputElement, InputTagsProps>(
  (
    {
      id,
      required,
      disabled,
      className,
      placeholder,
      label,
      maxTags,
      value,
      type,
      onTagsChange,
      onMaxTags,
      ...restProps
    },
    ref,
  ) => {
    const [localTags, setLocalTags] = useState<string[]>([])
    const [isInvalid, setIsInvalid] = useState(false)

    const ENTER_KEY_CODE = 188
    const COMMA_KEY_CODE = 13
    const DELETE_KEY_CODE = 8
    const SPACE_KEY_CODE = 32

    const preventEnterSubmit = (event: any) => {
      const keyCode = event.keyCode ? event.keyCode : event.which

      if (keyCode === 13) event.preventDefault()
    }

    const handleKeyUp = (event: any) => {
      const keyCode = event.keyCode ? event.keyCode : event.which

      if (isInvalid) return

      if (keyCode === ENTER_KEY_CODE || keyCode === COMMA_KEY_CODE || keyCode === SPACE_KEY_CODE) {
        const tag = event.target.value.trim().split(',')

        if (tag.length === 0 || tag[0] === '') {
          return
        }

        setLocalTags([...localTags, tag[0]])

        event.target.value = ''
      }
    }

    const handleKeyDown = (event: any) => {
      preventEnterSubmit(event)
      const keyCode = event.keyCode ? event.keyCode : event.which

      if (keyCode === DELETE_KEY_CODE && event.target.value === '') {
        removeTag(localTags.length - 1)
        return
      }
    }

    const removeTag = (index: number) => {
      const _tags = localTags.slice()

      _tags.splice(index, 1)
      setLocalTags(_tags)
    }

    useEffect(() => {
      setIsInvalid(localTags.length > maxTags)
      onTagsChange(localTags)
    }, [localTags])

    return (
      <div>
        {label && <InputTagsLabel>{label}</InputTagsLabel>}
        <InputTagsWrap>
          {localTags?.map((tag, index) => (
            <Chip
              key={index}
              color="primary"
              size="medium"
              sx={{ mr: 1 }}
              label={tag}
              onDelete={() => removeTag(index)}
            />
          ))}
          <label style={{ width: '100%' }}>
            <Input
              id={id}
              ref={ref}
              placeholder={localTags.length > 0 ? '' : placeholder}
              type="text"
              value={value}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
              {...restProps}
            />
          </label>
        </InputTagsWrap>
      </div>
    )
  },
)
