import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Menu, Stack, Typography } from '@mui/material'
import CollectionLogo from 'components/CollectionLogo'
import { useEffect, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import NearFTSDK from 'nearft-sdk'
import { useSelectedNFTCollection } from 'state/pool/hooks'
import NFTCollectionItem from './NFTCollectionItem'
import { CONTRACT_ID } from '../../../constants'
import { useAccount } from 'state/wallet/hooks'

function NFTCollectionSelect(): JSX.Element {
  const account = useAccount()
  const selectedCollection = useSelectedNFTCollection()

  const [listCollection, setListCollection] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      setLoading(true)
      if (account) {
        const _list = await NearFTSDK.getListMyCollection(
          process.env.REACT_APP_NETWORK_ID ?? 'testnet',
          CONTRACT_ID,
          account,
        )
        setListCollection(_list)
      }
      setLoading(false)
    })()
  }, [account])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        '@media (min-width: 36rem)': {
          justifyContent: 'flex-end',
        },
      }}
    >
      {/* <Typography variant="h5" display={{ xs: 'none', md: 'block' }} sx={{ mb: 0, mr: '1rem' }}>
        NFT Collection
      </Typography> */}
      {loading ? (
        <LoadingButton
          loading
          variant="contained"
          sx={{
            background: 'rgba(255, 255, 255, 0.2)',
            '&:hover': { background: 'rgba(255, 255, 255, 0.3)' },
          }}
        >
          Select Collection
        </LoadingButton>
      ) : (
        <>
          {listCollection.length > 0 && (
            <>
              <Button
                variant="contained"
                onClick={handleClick}
                endIcon={<FiChevronDown />}
                sx={{
                  textTransform: 'none',
                  background: 'rgba(255, 255, 255, 0.2)',
                  padding: { xs: '0.5rem', md: '0.5rem 1rem' },
                  width: '100%',
                  '&:hover': { background: 'rgba(255, 255, 255, 0.3)' },
                  '@media (min-width: 36rem)': {
                    width: 'fit-content',
                  },
                }}
              >
                <Grid container alignItems="center">
                  <Stack width="2rem" alignItems="center">
                    <CollectionLogo poolName={selectedCollection} size="2rem" borderRadius="0" />
                  </Stack>
                  <Typography
                    sx={{
                      mb: 0,
                      ml: '0.5rem',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 150,
                      '@media (min-width: 48rem)': {
                        width: 180,
                      },
                    }}
                  >
                    {selectedCollection ? selectedCollection : 'Select Collection'}
                  </Typography>
                </Grid>
              </Button>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {listCollection.map(collection => (
                  <NFTCollectionItem
                    key={collection}
                    collection={collection}
                    isSelected={collection === selectedCollection}
                    onSelect={handleClose}
                  />
                ))}
              </Menu>
            </>
          )}
        </>
      )}
    </Stack>
  )
}

export default NFTCollectionSelect
