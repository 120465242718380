import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { CgInfo } from 'react-icons/cg'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai'
import { Data, Order, HeadCell } from './sorting'

const StyledTableSortLabel = styled(TableSortLabel)`
  &:not(.Mui-active) svg {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  &.Mui-active .MuiIconButton-root svg {
    color: #fff !important;
  }
`

const HeaderLabel = styled.p`
  background: linear-gradient(90deg, #f37b4a 0%, #ed3024 99.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

const SortDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.25rem;
`

const SortButtonMobile2 = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 1rem;
  padding: 0.25rem 0.5rem;
  display: flex;
  font-size: 0.875rem;
`
const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: between;
  margin: 0;
  line-height: 1.25;
  min-height: 2.5rem;
  color: #000 !important;
`

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Name',
    tooltip: false,
    tooltipText: '',
    direction: 'up',
  },
  {
    id: 'listing',
    numeric: true,
    label: 'Listing',
    tooltip: false,
    tooltipText: '',
    direction: '',
  },
  {
    id: 'floorPrice',
    numeric: true,
    label: 'Floor Price',
    tooltip: true,
    tooltipText: 'The price of the cheapest NFT listed.',
    direction: '',
  },
  {
    id: 'liquidity',
    numeric: true,
    label: 'Pool Liquidity',
    tooltip: true,
    tooltipText: 'The total amount of NEAR locked in collection offers.',
    direction: '',
  },
  {
    id: 'volume',
    numeric: true,
    label: 'Volume',
    tooltip: true,
    tooltipText: 'The total amount of NEAR traded.',
    direction: '',
  },
]

function SortButton({ className }: { className: string }): JSX.Element {
  const direction = className.includes('Asc') ? 'up' : className.includes('Desc') ? 'down' : ''

  return (
    <SortDiv>
      <AiOutlineCaretUp size={10} color={direction === 'up' ? '#fff' : 'rgba(255, 255, 255, 0.5)'} />
      <AiOutlineCaretDown size={10} color={direction === 'down' ? '#fff' : 'rgba(255, 255, 255, 0.5)'} />
    </SortDiv>
  )
}

function SortButtonMobile({
  headCell,
  isActive,
  callback,
}: {
  headCell: HeadCell
  isActive: boolean
  callback: any
}): JSX.Element {
  const [sortDirection, setSortDirection] = useState(headCell.direction)

  const onClick = () => {
    const _direction = headCell.direction === '' ? 'up' : headCell.direction === 'down' ? 'up' : 'down'
    setSortDirection(_direction)
    headCell.direction = _direction
    headCells.forEach(h => {
      if (h.id !== headCell.id) {
        h.direction = ''
      }
    })
    callback()
  }
  return (
    <StyledMenuItem onClick={onClick}>
      <span>{headCell.label}</span>
      <SortDiv>
        <AiOutlineCaretUp size={10} color={isActive && sortDirection === 'up' ? '#000' : 'rgba(0, 0, 0, 0.5)'} />
        <AiOutlineCaretDown size={10} color={isActive && sortDirection === 'down' ? '#000' : 'rgba(0, 0, 0, 0.5)'} />
      </SortDiv>
    </StyledMenuItem>
  )
}

interface EnhancedTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
  isLoading: boolean
}

function EnhancedTableHead(props: EnhancedTableHeadProps): JSX.Element {
  const { order, orderBy, onRequestSort, isLoading } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const onOpenSortingMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseSortingMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {isMobile && !isLoading && (
        <>
          <SortButtonMobile2
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={onOpenSortingMenu}
            endIcon={open ? <FiChevronUp /> : <FiChevronDown />}
          >
            Sorting
          </SortButtonMobile2>
          <Menu anchorEl={anchorEl} open={open} onClose={onCloseSortingMenu}>
            {headCells.map(headCell => (
              <SortButtonMobile
                headCell={headCell}
                isActive={orderBy === headCell.id}
                callback={createSortHandler(headCell.id)}
              />
            ))}
          </Menu>
        </>
      )}
      <TableHead
        sx={{
          backgroundColor: 'transparent',
          display: 'none',
          '@media (min-width: 62rem)': { display: 'table-header-group' },
        }}
      >
        <TableRow>
          {headCells.map(headerCell => (
            <TableCell
              key={headerCell.id}
              sortDirection={orderBy === headerCell.id ? order : false}
              sx={{ borderBottom: 'none', padding: '0.75rem 1.25rem' }}
            >
              <StyledTableSortLabel
                IconComponent={({ className }: { className: string }) => <SortButton className={className} />}
                active={orderBy === headerCell.id}
                direction={orderBy === headerCell.id ? order : 'desc'}
                onClick={createSortHandler(headerCell.id)}
              >
                <HeaderLabel>
                  {headerCell.label}
                  {headerCell.tooltip && (
                    <Tooltip arrow title={headerCell.tooltipText} placement="top">
                      <IconButton>
                        <CgInfo size={18} />
                      </IconButton>
                    </Tooltip>
                  )}
                </HeaderLabel>
              </StyledTableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  )
}

export default EnhancedTableHead
