import { FaTwitter, FaGithub, FaDiscord } from 'react-icons/fa'
import styled from 'styled-components/macro'
import { Container, Link } from '@mui/material'

const StyledFooter = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  padding-left: 0;

  @media (min-width: 48rem) {
    margin-top: 0;
  }
`

const SocialItem = styled.div`
  padding: 0 1.25rem;

  a {
    color: #fff;
    transition: 0.5s ease all;

    &:hover {
      color: #ef4a31;
    }
  }
`

function Footer(): JSX.Element {
  return (
    <Container maxWidth="xl">
      <StyledFooter>
        <SocialLinks>
          <SocialItem>
            <Link href="#" target="_blank" rel="nofollow noreferrer noopener">
              <FaTwitter size="1.2rem" />
            </Link>
          </SocialItem>
          <SocialItem>
            <Link href="#" target="_blank" rel="nofollow noreferrer noopener">
              <FaDiscord size="1.2rem" />
            </Link>
          </SocialItem>
          <SocialItem>
            <Link href="#" target="_blank" rel="nofollow noreferrer noopener">
              <FaGithub size="1.2rem" />
            </Link>
          </SocialItem>
          <SocialItem>
            <Link href="#" target="_blank" rel="nofollow noreferrer noopener">
              Docs
            </Link>
          </SocialItem>
        </SocialLinks>
      </StyledFooter>
    </Container>
  )
}

export default Footer
