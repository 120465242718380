import { IconButton, Drawer, Box, Typography, Divider, Badge, BadgeProps, Tooltip, Button, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useCart } from 'react-use-cart'
import swal from 'sweetalert'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { BiCart } from 'react-icons/bi'
import { MdClose } from 'react-icons/md'
import NearFTSDK from 'nearft-sdk'
import { CONTRACT_ID } from '../../constants'
import { useWalletSelector } from 'state/wallet/hooks'
import { Link as RouterLink } from 'react-router-dom'
import CartItem from './CartItem'

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  color: '#ffffff',
  '& .MuiBadge-badge': {
    right: -2,
    top: 2,
    backgroundColor: '#007fff',
    padding: '0 4px',
  },
}))

function Cart(): JSX.Element {
  const { totalUniqueItems, items, isEmpty, emptyCart, removeItem } = useCart()
  const { selector, accountId } = useWalletSelector()
  const navigate = useNavigate()

  const [openCart, setOpenCart] = useState(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [tokens, setTokens] = useState<string[]>()

  const toggleCartDrawer = () => {
    setOpenCart(true)
  }

  const onBuy = async () => {
    setLoading(true)
    const slippage = 0.1
    const poolIds = items.map(i => i.poolId)
    const nftContracts = items.map(i => i.contractId)
    const tokenIds: string[][] = []

    for (let i = 0; i < poolIds.length; i++) {
      // eslint-disable-next-line no-loop-func
      const tokensInPool: string[] = items.filter(n => n.poolId === poolIds[i]).map(n => n.tokenId)
      tokenIds.push(tokensInPool)
    }

    NearFTSDK.buyMultiNFT(
      process.env.REACT_APP_NETWORK_ID ?? 'testnet',
      CONTRACT_ID,
      poolIds,
      nftContracts,
      tokenIds,
      slippage,
      selector,
      accountId,
    )
      .then(() => {
        emptyCart()
        navigate('/inventory')
      })
      .catch((e: { toString: () => any }) => {
        swal('Oops!', `Something went wrong! \\n ${e.toString()}`, 'error')
        setLoading(false)
      })
  }

  useEffect(() => {
    if (items) {
      const _tokens = []
      for (const item in items) {
        // @ts-ignore
        _tokens.push(item.tokenId)
      }
      setTokens(_tokens)
    }
  }, [items])

  return (
    <>
      <Tooltip title="Cart" arrow>
        <IconButton
          aria-label="Cart"
          sx={{
            ml: '16px',
          }}
          onClick={toggleCartDrawer}
        >
          <StyledBadge badgeContent={totalUniqueItems}>
            <BiCart />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      <Drawer anchor="right" open={openCart} onClose={() => setOpenCart(false)}>
        <Box sx={{ mt: '8px', p: '16px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box>
              <Stack direction="row" alignItems="center" justifyContent="flex-start">
                <Typography variant="subtitle2" sx={{ mb: 0 }}>
                  Your Cart ({totalUniqueItems})
                </Typography>
                {!isEmpty && (
                  <Tooltip title="Empty cart" placement="bottom">
                    <Button
                      variant="text"
                      size="small"
                      onClick={emptyCart}
                      sx={{ background: 'transparent !important', p: 0, ml: '2rem', fontSize: '12px' }}
                    >
                      Clear
                    </Button>
                  </Tooltip>
                )}
              </Stack>
            </Box>
            <IconButton aria-label="close" size="small" color="primary" onClick={() => setOpenCart(false)}>
              <MdClose color="#fff" />
            </IconButton>
          </Stack>
        </Box>
        <Divider />
        <Box sx={{ p: '16px' }}>
          {!isEmpty ? (
            <>
              {items?.map(item => (
                <CartItem key={item.id} item={item} />
              ))}
            </>
          ) : (
            <Typography sx={{ color: '#fff' }}>Your cart is empty</Typography>
          )}
        </Box>
        <Box sx={{ px: '16px' }}>
          {totalUniqueItems > 0 ? (
            <Button variant="contained" sx={{ width: '100%' }} onClick={onBuy}>
              Buy {totalUniqueItems} {totalUniqueItems > 1 ? 'NFTs' : 'NFT'}
            </Button>
          ) : (
            <Button variant="contained" component={RouterLink} sx={{ width: '100%' }} to="/collections">
              <span>Add an NFT to cart</span>
            </Button>
          )}
        </Box>
      </Drawer>
    </>
  )
}

export default Cart
