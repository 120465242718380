import React, { useEffect, useRef, useState } from 'react'
import { IMAGE_TYPES } from './FileFormats'
import AcceptedFormats from './AcceptedFormats'
import { fileHandler } from './FileFunctions'
import styled from 'styled-components/macro'
import { Typography } from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload'

interface InputMediaProps {
  acceptedFormats: string[]
  idealDimensions: string
  uploadedFile: File
  handleFileAdd: (file: File) => void
  handleFileRemove: () => void
  maxFileSize?: number
}

const InputMediaDropzone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  color: #ffffff;
  border: 1px solid #979797;
  cursor: pointer;
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  border: 1px solid #979797;

  div {
    width: 240px;
    height: 240px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

// const InputMedia

const preventBrowserDefaults = (e: Event) => {
  e.preventDefault()
  e.stopPropagation()
}

export const InputMedia = (props: InputMediaProps) => {
  const { idealDimensions, acceptedFormats, uploadedFile, handleFileAdd, handleFileRemove, maxFileSize = 5 } = props

  const [imageUrl, setImageUrl] = useState<any>('')
  const [internalErrorMessage, setInternalErrorMessage] = useState<string | null>(null)

  const dragRef = useRef(0)
  const [dragOverlay, setDragOverlay] = useState(false)

  const handleDrag = (event: any) => {
    preventBrowserDefaults(event)
  }

  const handleDragIn = (event: any) => {
    preventBrowserDefaults(event)
    dragRef.current++
    setDragOverlay(true)
  }

  const handleDragOut = async (event: any) => {
    preventBrowserDefaults(event)
    dragRef.current--
    if (dragRef.current === 0) {
      setDragOverlay(false)
    }
  }

  const handleDrop = async (event: any) => {
    preventBrowserDefaults(event)
    setDragOverlay(false)

    dragRef.current = 0

    if (!event.dataTransfer.files.length) {
      return
    }

    await handleFileChange(event.dataTransfer.files[0], true)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileChange = async (e: any, isDrag?: boolean) => {
    if (!isDrag) {
      if (!(e?.target?.files.length > 0)) return
    }
    const file = isDrag ? e : e.target.files[0]

    setInternalErrorMessage(null)

    const tFile = await fileHandler(file)
    const correctFile = acceptedFormats.find((item) => item === tFile.type)

    // validate file size and accepted formats
    const { size } = file

    if (correctFile) {
      if (size / 1024 / 1024 <= maxFileSize) {
        handleFileAdd(tFile)
      } else {
        setInternalErrorMessage(`This file exceeds ${maxFileSize}mb`)
        return
      }
    } else {
      setInternalErrorMessage('This media type is not accepted')
      return
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const removeFile = (e: any) => {
    e.preventDefault()
    handleFileRemove()
  }

  useEffect(() => {
    if (uploadedFile && IMAGE_TYPES.includes(uploadedFile.type)) {
      setImageUrl(URL.createObjectURL(uploadedFile))
    } else {
      setImageUrl(null)
    }
  }, [uploadedFile])

  return (
    <>
      {uploadedFile && (
        <ImgWrapper>
          <div>
            <img src={imageUrl} alt={uploadedFile.name} />
          </div>
        </ImgWrapper>
      )}
      <InputMediaDropzone>
        <label
          onDragEnter={handleDragIn}
          onDragLeave={handleDragOut}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          style={{ width: '100%', cursor: 'pointer' }}
        >
          <div>
            <FileUploadIcon fontSize="large" />
            <Typography variant="h4">Upload File</Typography>
            <Typography>(or just drop your file here)</Typography>
            {uploadedFile && <p onClick={removeFile}>Remove File</p>}
            {internalErrorMessage && <Typography sx={{ color: 'rgb(211, 47, 47)' }}>{internalErrorMessage}</Typography>}
          </div>

          <input
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            onClick={(e) => ((e.target as HTMLInputElement).value = '')}
          />
        </label>
      </InputMediaDropzone>
      <AcceptedFormats acceptedFormats={acceptedFormats} idealDimensions={idealDimensions} maxSize={maxFileSize} />
    </>
  )
}
