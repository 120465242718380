import { Box, Grid, IconButton, InputAdornment, SxProps, TextField, Tooltip, Typography } from '@mui/material'
import { useSelectedNFTCollection } from 'state/pool/hooks'
import NearFTSDK from 'nearft-sdk'
import { CONTRACT_ID } from '../../constants'
import { useEffect, useState } from 'react'
import { IPool } from 'interfaces'
import { toRoundedReadableNumber } from 'utils/number'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

interface NumberInput {
  label: string
  description: string
  defaultValue?: any
  invalidMessage?: string
  validWhen?: boolean
  showFloorPrice?: boolean
  adorment?: JSX.Element
  sx?: SxProps
  isCollectionInput?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const nftCSS = {
  '.MuiInputBase-root': {
    paddingRight: '3px',
    display: 'block',
  },
  '.MuiInputBase-input': {
    width: '100%',
  },
  '.MuiInputAdornment-root': {
    width: '100%',
    justifyContent: 'flex-end',
  },
  '.MuiButtonBase-root': {
    backgroundColor: 'transparent !important',
    paddingY: 0,
  },
  '@media (min-width: 36rem)': {
    '.MuiInputBase-root': {
      display: 'flex',
    },
  },
  '@media (min-width: 62rem)': {
    '.MuiButtonBase-root': {
      width: '18rem',
    },
    '.MuiInputAdornment-root': {
      width: 'auto',
    },
    '.MuiInputBase-input': {
      width: 'calc(100% - 18rem)',
    },
  },
}

function NumberInput(props: NumberInput): JSX.Element {
  const {
    label,
    description,
    defaultValue,
    invalidMessage,
    validWhen,
    showFloorPrice,
    adorment,
    isCollectionInput,
    onChange,
    sx,
  } = props
  const [floorPrice, setFloorPrice] = useState('')
  const selectedCollection = useSelectedNFTCollection()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const _pools: IPool[] = await NearFTSDK.getPools(process.env.REACT_APP_NETWORK_ID ?? 'testnet', CONTRACT_ID)
      const pool = _pools.filter(p => p.nft_token === selectedCollection)

      if (pool.length > 0) {
        setFloorPrice(
          toRoundedReadableNumber({
            number: pool[0].spot_price,
            precision: 3,
          }),
        )
      }
    })()
  }, [])

  return (
    <Box marginBottom="0.75rem" sx={isCollectionInput ? nftCSS : sx}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid container alignItems="center" sx={{ mb: '0.5rem' }}>
          {label && <Typography sx={{ fontWeight: '600', mr: '0.5rem', mb: 0 }}>{label}</Typography>}
          {description && (
            <Tooltip title={description} placement="top" arrow>
              <IconButton sx={{ p: 0 }}>
                <AiOutlineQuestionCircle size={16} color="#fff" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        {showFloorPrice && floorPrice && <Typography sx={{ fontWeight: '600' }}>Floor Price: {floorPrice}</Typography>}
      </Grid>
      <TextField
        fullWidth
        error={validWhen === false}
        defaultValue={defaultValue ?? 0}
        type="number"
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{adorment}</InputAdornment>,
        }}
        helperText={validWhen === false ? invalidMessage : ''}
        onChange={onChange}
      />
    </Box>
  )
}

export default NumberInput
